import type { AxiosInstance, AxiosRequestConfig } from "axios";
import type { PipelineStage, PipelineStageId, PipelineStageMetrics } from "./PipelineStage";
import type { ApiListResponse } from "../../commonTypes";
import type { PipelineStageFilter } from "./PipelineStageFilter";
import { pipelineStageFilterToServerFormat } from "./PipelineStageFilter";

export const createPipelineStageApi = (axios: AxiosInstance) => {
  const getPipelineStages = async (params: {
    limit: number
    offset: number
    filter?: PipelineStageFilter
  }) => {
    return axios.get<ApiListResponse<PipelineStage>>("/api/pipeline-stages", {
      params: {
        filter: pipelineStageFilterToServerFormat(params.filter),
        limit: params.limit,
        offset: params.offset,
      },
    });
  };

  const getPipelineStage = async (id: PipelineStageId) => {
    return axios.get<PipelineStage>(`/api/pipeline-stages/${id}`);
  };

  const createPipelineStage = async (
    params: {
      fields: Pick<PipelineStage, "name" | "object" | "category"> & Partial<Pick<PipelineStage, "type" | "order">>
    },
  ) => {
    return axios.post<PipelineStage>("/api/pipeline-stages", params.fields);
  };

  const updatePipelineStage = async (params: Pick<PipelineStage, "name" | "type" | "order" | "category">) => {
    return axios.put<PipelineStage>("/api/pipeline-stages", params);
  };

  const deletePipelineStage = async (id: PipelineStageId, replaceId: PipelineStageId) => {
    return axios.delete<unknown>(`/api/pipeline-stages/${id}`, {
      params: {
        replace_pipeline_stage_uuid: replaceId,
      },
    });
  };

  const getPipelineStagesMetrics = async (
    params: {
      object: PipelineStage["object"]
      objectElasticQuery?: unknown
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.put<Record<PipelineStageId, PipelineStageMetrics>>(
      "/api/pipeline-stages/metrics",
      {
        object: params.object,
        filter: {
          elasticQuery: params.objectElasticQuery,
        },
      },
      config,
    );
  };

  return {
    getPipelineStages,
    getPipelineStage,
    createPipelineStage,
    updatePipelineStage,
    deletePipelineStage,
    getPipelineStagesMetrics,
  };
};
