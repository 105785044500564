import { matchPath } from "react-router-dom";
import basePath from "../path";

const flowListTabs = ["main", "archived"] as const;
export type FlowListTab = typeof flowListTabs[number];
const flowListTabPathName: Record<FlowListTab, string> = {
  main: "automation",
  archived: "archive",
};

export const flowListPath = `${basePath}/(${flowListTabs.map((item) => flowListTabPathName[item]).join("|")})`;

export const getFlowListPageLink = (params: {
  tab: FlowListTab
  // page: number
  // pageSize: number
  // query: string
  // workspace: FlowWorkspaceId | null
}) => {
  return `${basePath}/${flowListTabPathName[params.tab]}`;
};

export const flowListPageQueryParams = {
  PAGE: "page",
  PAGE_SIZE: "pageSize",
  QUERY: "q",
  WORKSPACE: "folder",
} as const;

export const getFLowListTabFromPathname = (pathname: string): FlowListTab | null => {
  const matchRes = matchPath<{0: string}>(pathname, flowListPath);
  if (!matchRes) return null;
  const tabQueryValue = matchRes.params[0];

  return flowListTabs.find((flowListTab) => flowListTabPathName[flowListTab] === tabQueryValue) || null;
};
