import type { AxiosInstance, AxiosRequestConfig } from "axios";
import type { SenderProfile, SenderProfileId, SenderProfileMetrics } from "./SenderProfile";
import type { ApiListResponse, Mixed } from "../../commonTypes";
import type { LinkedinAccountId } from "../LinkedinAccount/LinkedinAccount";
import type { MailboxId } from "../Mailbox/Mailbox";
import type { UserId } from "../User/User";


export const createSenderProfileApi = (axios: AxiosInstance) => {
  const getSenderProfiles = (params: {
    limit: number
    offset: number
    filter?: {
      query?: string
      id?: Mixed<SenderProfileId>
      linkedinAccountId?: Mixed<LinkedinAccountId>
      mailboxId?: Mixed<MailboxId>
      status?: Mixed<SenderProfile["status"]>
      assigneeUserId?: Mixed<UserId>
      userId?: Mixed<UserId>
      firstName?: Mixed<string>
      lastName?: Mixed<string>
      label?: Mixed<string>
      withDeleted?: boolean
    }
  }) => {
    return axios.post<ApiListResponse<SenderProfile>>("/api/sender-profiles/list", {
      filter: {
        q: params.filter?.query,
        uuid: params.filter?.id,
        linkedin_account_uuid: params.filter?.linkedinAccountId,
        mailbox_uuid: params.filter?.mailboxId,
        status: params.filter?.status,
        assignee_user_id: params.filter?.assigneeUserId,
        user_id: params.filter?.userId,
        first_name: params.filter?.firstName,
        last_name: params.filter?.lastName,
        label: params.filter?.label,
        deleted: params.filter?.withDeleted,
      },
      limit: params.limit,
      offset: params.offset,
    });
  };

  const getSenderProfile = (params: {id: SenderProfileId}, config?: AxiosRequestConfig) => {
    return axios.get<SenderProfile>(`/api/sender-profiles/${params.id}`, config);
  };

  // deprecated: use leadApi.getLeadMetrics
  const getSenderProfilesMetrics = async (
    params: {ids: SenderProfileId[]; leadElasticQuery?: unknown},
    config?: AxiosRequestConfig,
  ) => {
    const response = await axios.post<{total: SenderProfileMetrics} & Record<SenderProfileId, SenderProfileMetrics>>(
      "/api/sender-profiles/metrics",
      {
        uuids: params.ids,
        filter: {
          elasticQuery: params.leadElasticQuery,
        },
      },
      config,
    );
    const total = response.data.total as SenderProfileMetrics;
    const metrics = { ...response.data } as Record<SenderProfileId, SenderProfileMetrics>;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete metrics.total;

    return {
      ...response,
      data: {
        total,
        metrics,
      },
    };
  };

  const updateSenderProfile = (
    params: {
      senderProfileId: SenderProfileId
      fields: {
        firstName?: string
        lastName?: string
        label?: string
        assigneeUserId?: UserId
        schedule?: SenderProfile["schedule"]
      }
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.put<SenderProfile>(
      `/api/sender-profiles/${params.senderProfileId}`,
      {
        first_name: params.fields.firstName,
        last_name: params.fields.lastName,
        label: params.fields.label,
        assignee_user_id: params.fields.assigneeUserId,
        schedule: params.fields.schedule,
      },
      config,
    );
  };

  const createSenderProfile = (
    params: {
      fields: {
        firstName: string
        lastName: string
        label?: string
        assigneeUserId?: UserId
        schedule?: Partial<SenderProfile["schedule"]>
      }
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.post<SenderProfile>(
      "/api/sender-profiles",
      {
        first_name: params.fields.firstName,
        last_name: params.fields.lastName,
        label: params.fields.label,
        assignee_user_id: params.fields.assigneeUserId,
        schedule: params.fields.schedule,
      },
      config,
    );
  };

  const enableSenderProfile = (params: { id: SenderProfileId }, config?: AxiosRequestConfig) => {
    return axios.put<SenderProfile>(`/api/sender-profiles/${params.id}/enable`, null, config);
  };

  const disableSenderProfile = (params: { id: SenderProfileId }, config?: AxiosRequestConfig) => {
    return axios.put<SenderProfile>(`/api/sender-profiles/${params.id}/disable`, null, config);
  };

  const deleteSenderProfile = (params: { id: SenderProfileId }, config?: AxiosRequestConfig) => {
    return axios.delete<void>(`/api/sender-profiles/${params.id}`, config);
  };

  return {
    getSenderProfiles,
    getSenderProfile,
    updateSenderProfile,
    getSenderProfilesMetrics,
    createSenderProfile,
    enableSenderProfile,
    disableSenderProfile,
    deleteSenderProfile,
  };
};
