import { getBaseAxios } from "services/axios";
import { createTagApi } from "@gs/core/domain/Tag/createTagApi";
import type { Tag, TagId } from "@gs/core/domain/Tag/Tag";
import { tagStore } from "./tagStore";
import { da } from "date-fns/locale";
import { dataBus } from "services/dataBus";

export const tagApi = (() => {
  const leadsAxios = getBaseAxios("contactV2");

  const coreTagApi = createTagApi(leadsAxios);

  const createTag: typeof coreTagApi.createTag = async (...args) => {
    const result = await coreTagApi.createTag(...args);

    tagStore.setState({
      [result.data.uuid]: result.data,
    });

    dataBus.emit("tag:created", { tagIds: [result.data.uuid] });

    return result;
  };

  const getTags: typeof coreTagApi.getTags = async (...args) => {
    const result = await coreTagApi.getTags(...args);

    tagStore.setState(
      result.data.data.reduce<Record<TagId, Tag>>((acc, tag) => {
        acc[tag.uuid] = tag;
        return acc;
      }, {}),
    );

    return result;
  };

  const updateTag: typeof coreTagApi.updateTag = async (...args) => {
    const result = await coreTagApi.updateTag(...args);

    tagStore.setState({
      [result.data.uuid]: result.data,
    });

    dataBus.emit("tag:updated", { tagIds: [result.data.uuid] });

    return result;
  };

  const deleteTag: typeof coreTagApi.deleteTag = async (...args) => {
    const result = await coreTagApi.deleteTag(...args);

    dataBus.emit("tag:deleted", { tagIds: [args[0].id] });

    return result;
  };

  return {
    createTag,
    getTags,
    updateTag,
    deleteTag,
    getTagMetrics: coreTagApi.getTagMetrics,
  };
})();
