import { matchPath } from "react-router";
import basePath from "../path";

export type StoplistObject = "lead" | "company" // todo: move outside of path.ts
export const stoplistsPath = `${basePath}/stoplists`;
export const objectParamName = "object";
export const stoplistsByObjectPath = `${stoplistsPath}/:${objectParamName}`;

export const getObjectParamValue = () => {
  const paramValue = matchPath<any>(window.location.pathname, stoplistsByObjectPath)?.params?.[objectParamName];
  if (!paramValue) return null;
  return getStoplistObjectByUrlParam(paramValue);
};

export const stoplistObjectUrlParamMap = {
  lead: "contact",
  company: "account",
} as const satisfies Record<StoplistObject, string>;

export const getStoplistObjectByUrlParam = (urlParam: string): StoplistObject | null => {
  for (const [theStoplistObject, theUrlParam] of Object.entries(stoplistObjectUrlParamMap)) {
    if (theUrlParam === urlParam) return theStoplistObject as StoplistObject;
  }

  return null;
};

export const stoplistsPageQueryParams = {
  PAGE: "page",
  PAGE_SIZE: "pageSize",
  SEARCH: "q",
} as const;

export const getLinkToStoplistsPage = (args?: {
  stoplistObject?: StoplistObject
}) => {
  // TODO: use stoplistsPageQueryParams
  if (!args) return stoplistsPath;
  if (!args.stoplistObject) return stoplistsPath;

  return stoplistsByObjectPath.replace(new RegExp(`:${objectParamName}\\b`), stoplistObjectUrlParamMap[args.stoplistObject]);
};

export default stoplistsPath;
