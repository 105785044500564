import { Route, Switch, Redirect } from "react-router-dom";
import Error404 from "components/routes/NotFound";
import { reportsPath, AsyncReportsPage } from "./ReportsPage";
import { getStartedPath, AsyncGetStartedPage } from "./GetStartedPage";
import { ContentHeader } from "feature/mainLayout/component/ContentHeader/ContentHeader";
import { useCurrentUser } from "feature/session/hook/useCurrentUser";
import { useMemo } from "react";
import { dashboardPath } from "./path";
import { useOnboardingSteps } from "./GetStartedPage/onboardingSteps";

export const DashboardPage = () => {
  const { currentUser } = useCurrentUser();
  const onboardingSteps = useOnboardingSteps();
  const isOnboardingAvailable = useMemo(() => {
    if (currentUser?.config.show_starter_guide === false) return false;

    return !!onboardingSteps;
  }, [currentUser?.config.show_starter_guide, onboardingSteps]);

  const tabs = useMemo(() => {
    const reportsTab = {
      label: "Reports",
      path: reportsPath,
    };

    if (!isOnboardingAvailable) {
      return [reportsTab];
    }

    return [
      {
        label: "Get Started",
        path: getStartedPath,
      },
      reportsTab,
    ];
  }, [onboardingSteps]);

  return (
    <>
      <ContentHeader>
        <ContentHeader.Tabs.Navigation tabs={tabs} />
      </ContentHeader>

      <Switch>
        <Route
          path={dashboardPath}
          exact
        >
          <Redirect to="/" />
        </Route>
        {!!onboardingSteps && (
          <Route
            path={getStartedPath}
            component={AsyncGetStartedPage}
          />
        )}
        <Route
          path={reportsPath}
          component={AsyncReportsPage}
        />
        <Route>
          <Error404 />
        </Route>
      </Switch>
    </>
  );
};

