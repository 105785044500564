import { Button } from "@gs/uiKit/general/Button";
import { Popover } from "@gs/uiKit/dataDisplay/Popover";
import { Flex } from "@gs/uiKit/layout/Flex";
import { InnerLink } from "components/common/routing/InnerLink";
import subscriptionsPath from "components/routes/UserSettingsGroup/Subscriptions/path";
import { useCurrentSubscription } from "feature/session/hook/useCurrentSubscription";
import { useCurrentLicense } from "feature/session/hook/useCurrentLicense";
import { useCurrentTeam } from "feature/session/hook/useCurrentTeam";
import { getIsCustomSubscription } from "domain/subscription/util/getIsCustomSubscription";
import type { Subscription } from "@gs/core/domain/Subscription/Subscription";
import { modalService } from "feature/modal/ModalService";
import { getIsPlanUpgradable } from "domain/plan/plans";
import { bookCall } from "feature/bookCall";
import type { ComponentType } from "react";
import { useEffect } from "react";
import { useMemo, useState } from "react";
import { leadApi } from "domain/lead/leadApi";
import { userApi } from "domain/user/userApi";
import { NeverError } from "@gs/core/errors/NeverError";
import { ReactComponent as EmptyBatteryIcon } from "bootstrap-icons/icons/battery.svg";
import { ReactComponent as HalfBatteryIcon } from "bootstrap-icons/icons/battery-half.svg";
import { ReactComponent as FullBatteryIcon } from "bootstrap-icons/icons/battery-full.svg";
import { useSelector } from "react-redux";
import { getIsWhiteLabel } from "store/whiteLabel/selectors";


type LimitStatus = "low" | "medium" | "high" | "full";

const limitStatusColor = {
  low: "#1B914A",
  medium: "#EE8E36",
  high: "#B8211D",
  full: "#B8211D",
} as const satisfies Record<LimitStatus, string>;

const limitStatusIcon = {
  low: FullBatteryIcon,
  medium: HalfBatteryIcon,
  high: EmptyBatteryIcon,
  full: EmptyBatteryIcon,
} as const satisfies Record<LimitStatus, ComponentType>;

const getLimitStatus = (max: null | number, value: null | number): LimitStatus => {
  if (!max) return "low";
  if (value == null) return "low";

  const ratio = value / max;

  if (ratio <= 0.7) {
    return "low";
  } else if (ratio <= 0.95) {
    return "medium";
  } else if (ratio < 1) {
    return "high";
  } else {
    return "full";
  }
};

export const CreditsWidget = () => {
  const [currentSubscription] = useCurrentSubscription();
  const [currentLicense] = useCurrentLicense();
  const currentTeam = useCurrentTeam();
  const [usersCount, setUsersCount] = useState<number>();
  const [leadsCount, setLeadsCount] = useState<number>();
  const isWhiteLabel = useSelector(getIsWhiteLabel);

  const updateData = () => {
    leadApi.getLeads({ offset: 0, limit: 1 })
      .then((r) => setLeadsCount(r.data.total));
    userApi.getUsers({ offset: 0, limit: 1 })
      .then((r) => setUsersCount(r.data.total));
  };

  useEffect(() => {
    updateData();
  }, []);

  const isPlanUpgradable = currentLicense?.plan_id ? getIsPlanUpgradable(currentLicense.plan_id) : false;

  const handleUpgrade = (sub: Subscription) => {
    if (getIsCustomSubscription(sub)) {
      modalService.openModal("newSubscriptionModalId", {});
    } else {
      if (isPlanUpgradable) {
        modalService.openModal("changePlanModalId", { type: "upgrade", subscriptionId: sub.id });
      } else {
        bookCall();
      }
    }
  };

  const renderValue = (max: null | undefined | number, value?: null | number) => {
    if (max == null) {
      return <span className="gs-c-tertiary"> Unlimited </span>;
    }
    if (value == null) {
      return <span>{max}</span>;
    }

    let color = "";
    if (value != 0 && max != 0) {
      color = limitStatusColor[getLimitStatus(max, value)];
    }

    return (
      <span style={{ color }}>
        {value}
        /
        {max}
      </span>
    );
  };

  // const s = useSubs
  const popoverContent = (
    <div style={{ width: 300 }}>
      <Flex
        justify="space-between"
        align="center"
      >
        <span className="gs-fw-600">
          {currentSubscription?.name}
        </span>
        <InnerLink to={subscriptionsPath}>
          <Button
            type="link"
            size="small"
          >
            Manage
          </Button>
        </InnerLink>
      </Flex>

      {/*{currentLicense && (*/}
      {/*  <div className="gs-c-secondary gs-mt-xxs">*/}
      {/*    Next credit top up:*/}
      {/*    {" "}*/}
      {/*    {dateFormat(currentLicense.next_credit_top_up_at, "MMM D, YYYY")}*/}
      {/*  </div>*/}
      {/*)}*/}

      {currentTeam && (
        <div className="gs-mt-xs">
          <div className="gs-fw-500 gs-mb-xxs">
            Credits
            {" "}
            &nbsp;
            <span className="gs-fw-400 gs-c-secondary">Top up monthly</span>
          </div>
          <Flex
            className="gs-mb-xxs"
            justify="space-between"
          >
            <span className="gs-c-secondary">Enrichment</span>
            {renderValue(currentTeam.credits.advanced_enrichment || null)}
          </Flex>
          <Flex
            className="gs-mb-xxs"
            justify="space-between"
          >
            <span className="gs-c-secondary">Activities</span>
            {renderValue(currentTeam.credits.activities || null)}
          </Flex>

          <div className="gs-fw-500 gs-mb-xxs">
            Limits
            {" "}
            &nbsp;
            <span className="gs-fw-400 gs-c-secondary">Total plan limit numbers</span>
          </div>
          <Flex
            className="gs-mb-xxs"
            justify="space-between"
          >
            <span className="gs-c-secondary">Sender Profiles</span>
            {renderValue(currentTeam.features.sender_profiles)}
          </Flex>
          <Flex
            className="gs-mb-xxs"
            justify="space-between"
          >
            <span className="gs-c-secondary">Contacts</span>
            {renderValue(currentTeam.features.contacts, leadsCount)}
          </Flex>
          <Flex
            className="gs-mb-xxs"
            justify="space-between"
          >
            <span className="gs-c-secondary">Users</span>
            {renderValue(currentTeam.features.users, usersCount)}
          </Flex>
        </div>
      )}

      {!isWhiteLabel && currentSubscription && (
        <Button
          onClick={() => handleUpgrade(currentSubscription)}
          type="primary"
          className="gs-w-100p gs-mt-xs"
        >
          Upgrade
        </Button>
      )}
    </div>
  );

  const contactsLimitStatus = getLimitStatus(currentTeam?.features.contacts ?? null, leadsCount ?? null);
  const enrichmentLimitStatus = getLimitStatus(currentTeam?.credits.advanced_enrichment ?? null, null);
  const activitiesLimitStatus = getLimitStatus(currentTeam?.credits.activities ?? null, null);

  const totalStatus = useMemo<LimitStatus>(() => {
    const allStatuses = [contactsLimitStatus, enrichmentLimitStatus, activitiesLimitStatus];
    if (allStatuses.some((status) => status === "full")) return "full";
    if (allStatuses.some((status) => status === "high")) return "high";
    if (allStatuses.some((status) => status === "medium")) return "medium";
    return "low";
  }, [contactsLimitStatus, enrichmentLimitStatus, activitiesLimitStatus]);

  const totalTitle = useMemo(() => {
    switch (totalStatus) {
      case "low": return "Credits";
      case "medium": return "Running low on credits";
      case "high": return "Running low on credits";
      case "full": return "No credits left";
      default: throw new NeverError(totalStatus);
    }
  }, [totalStatus]);

  const TitleIcon = limitStatusIcon[totalStatus];

  return (
    <Popover
      content={popoverContent}
      trigger="click"
      placement="bottomLeft"
      arrow={false}
      fresh
      onOpenChange={(isOpen) => {
        if (isOpen) {
          updateData();
        }
      }}
    >
      <Button>
        <Flex
          gap="small"
          align="center"
        >
          <TitleIcon style={{ color: limitStatusColor[totalStatus] }} />
          {totalTitle}
        </Flex>
      </Button>
    </Popover>
  );
};
