import { Redirect } from "react-router-dom";
import { getStartedPath } from "components/routes/dashboard/GetStartedPage/path";
import { reportsPath } from "components/routes/dashboard/ReportsPage/path";
import { findGetParameter } from "services/utils";
import { useCurrentUser } from "feature/session/hook/useCurrentUser";
import { useMemo } from "react";
import { useOnboardingSteps } from "components/routes/dashboard/GetStartedPage/onboardingSteps";

export default () => {
  const { currentUser } = useCurrentUser();
  const onboardingSteps = useOnboardingSteps();

  const defaultRedirectUrl = useMemo(() => {
    if (onboardingSteps && currentUser?.config.show_starter_guide !== false) {
      return getStartedPath;
    }

    return reportsPath;
  }, [currentUser?.config.show_starter_guide, onboardingSteps]);

  return (
    <Redirect to={findGetParameter("redirect_url") || defaultRedirectUrl} />
  );
};
