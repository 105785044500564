import type { AxiosRequestConfig, AxiosInstance } from "axios";
import type { ApiListResponse, Mixed, OrderType } from "../../commonTypes";
import type { Tag, TagId } from "./Tag";
import type { UserId } from "../User/User";
import type { TagMetrics } from "./TagMetrics";

type TagEditableFields = Pick<Tag, "name">

export const createTagApi = (axios: AxiosInstance) => {
  const getTags = (
    params: {
      filter?: {
        query?: string
        id?: Mixed<TagId>
        name?: Mixed<string>
        userId?: Mixed<UserId>
        withDeleted?: boolean
      }
      limit: number
      offset: number
      orderField?: string
      orderType?: OrderType
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.post<ApiListResponse<Tag>>(
      "/api/tags/list",
      {
        order_field: params.orderField,
        order_type: params.orderType,
        limit: params.limit,
        offset: params.offset,
        filter: {
          q: params.filter?.query,
          uuid: params.filter?.id,
          name: params.filter?.name,
          user_id: params.filter?.userId,
          deleted: params.filter?.withDeleted,
        },
      },
      config,
    );
  };

  const createTag = async (params: {fields: TagEditableFields}, config?: AxiosRequestConfig) => {
    return axios.post<Tag>("/api/tags", params.fields, config);
  };

  const updateTag = async (params: {id: TagId; newFields: TagEditableFields}, config?: AxiosRequestConfig) => {
    return axios.put<Tag>(`/api/tags/${params.id}`, params.newFields, config);
  };

  const deleteTag = async (params: {id: TagId}, config?: AxiosRequestConfig) => {
    return axios.delete<void>(`/api/tags/${params.id}`, config);
  };

  const getTagMetrics = async <MetricKeys extends readonly (keyof TagMetrics)[]>(
    params: {
      metrics: MetricKeys
      ids: TagId[]
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.post<Record<TagId, {[key in MetricKeys[number]]: TagMetrics[key]}>>(
      "/api/tags/metrics",
      {
        uuids: params.ids,
        metrics: params.metrics,
      },
      config,
    );
  };

  return {
    getTags,
    createTag,
    updateTag,
    deleteTag,
    getTagMetrics,
  };
};
