export const emailActivityTypes = [
  "email_sent",
  "email_replied",
  "email_opened",
  "email_bounced",
  "email_link_clicked",
  "email_lead_unsubscribed",
] as const;

export type EmailActivityType = typeof emailActivityTypes[number]

export const linkedinActivityTypes = [
  "linkedin_connection_request_sent",
  "linkedin_connection_request_accepted",
  "linkedin_connection_request_withdrawn",
  "linkedin_blocked",
  "linkedin_inmail_sent",
  "linkedin_message_sent",
  "linkedin_message_replied",
  "linkedin_message_opened",
  "linkedin_link_clicked",
  "linkedin_post_liked",
  "linkedin_skills_endorsed",
  "linkedin_profile_visited",
  "linkedin_post_commented",
] as const;

export type LinkedinActivityType = typeof linkedinActivityTypes[number]

export const flowActivityTypes = [
  "added_to_flow",
  "flow_cancelled",
  "flow_finished",
  "flow_failed",
  "flow_restarted",
] as const;

export type FlowActivityType = typeof flowActivityTypes[number]

export const otherActivityTypes = [
  "created",
  "enriched",
  "updated",
  "deleted",
  "tags_added",
  "tags_removed",
  "synced_crm",
  "sender_profile_changed",
  "pipeline_stage_changed",
  "list_changed",
  "list_added",
  "list_removed",
  "manual_task_completed",
  "note_left",
] as const;

export type OtherActivityType = typeof otherActivityTypes[number]

export type ActivityType =
  | EmailActivityType
  | LinkedinActivityType
  | FlowActivityType
  | OtherActivityType


export const activityLabels: Record<ActivityType, string> = {
  added_to_flow: "Automation started",
  created: "Contact created",
  deleted: "Contact deleted",
  email_bounced: "Email bounced",
  enriched: "Enriched",
  email_link_clicked: "Email link clicked",
  email_replied: "Email replied",
  email_sent: "Email sent",
  flow_cancelled: "Automation cancelled",
  flow_restarted: "Flow restarted",
  flow_failed: "Automation failed",
  flow_finished: "Automation finished",
  linkedin_connection_request_accepted: "LinkedIn connection request accepted",
  linkedin_connection_request_sent: "LinkedIn connection request sent",
  linkedin_connection_request_withdrawn: "LinkedIn connection request withdrawn",
  linkedin_link_clicked: "LinkedIn link clicked",
  linkedin_message_opened: "LinkedIn message opened",
  linkedin_message_replied: "LinkedIn message replied",
  linkedin_inmail_sent: "LinkedIn InMail sent",
  linkedin_message_sent: "LinkedIn message sent",
  linkedin_post_commented: "LinkedIn profile post commented",
  linkedin_post_liked: "LinkedIn profile post liked",
  linkedin_profile_visited: "LinkedIn profile visited",
  linkedin_skills_endorsed: "LinkedIn profile skills endorsed",
  email_opened: "Email opened",
  email_lead_unsubscribed: "Email lead unsubscribed",
  linkedin_blocked: "LinkedIn account blocked",
  list_added: "List added",
  list_removed: "List removed",
  list_changed: "Contact's list updated",
  pipeline_stage_changed: "Contact's pipeline stage updated",
  sender_profile_changed: "Sender profile changed",
  synced_crm: "Contact synced to CRM",
  tags_added: "Tag added",
  tags_removed: "Tag removed",
  updated: "Contact updated",
  manual_task_completed: "Manual task completed",
  note_left: "Note",
};

export const isMessageActivityMap: Record<ActivityType, boolean> = {
  linkedin_connection_request_sent: true,
  linkedin_inmail_sent: true,
  linkedin_message_sent: true,
  email_sent: true,
  email_replied: true,
  linkedin_message_replied: true,

  added_to_flow: false,
  created: false,
  deleted: false,
  email_bounced: false,
  enriched: false,
  email_link_clicked: false,
  flow_cancelled: false,
  flow_restarted: false,
  flow_failed: false,
  flow_finished: false,
  linkedin_connection_request_accepted: false,
  linkedin_connection_request_withdrawn: false,
  linkedin_link_clicked: false,
  linkedin_message_opened: false,
  linkedin_post_commented: false,
  linkedin_post_liked: false,
  linkedin_profile_visited: false,
  linkedin_skills_endorsed: false,
  email_opened: false,
  email_lead_unsubscribed: false,
  linkedin_blocked: false,
  list_added: false,
  list_removed: false,
  list_changed: false,
  pipeline_stage_changed: false,
  sender_profile_changed: false,
  synced_crm: false,
  tags_added: false,
  tags_removed: false,
  updated: false,
  manual_task_completed: false,
  note_left: false,
};
