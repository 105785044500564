import { NavigationCategory } from "./Category";
import { templateCategories } from "../../const";

import styles from "../Modal.module.scss";

export const Navigation = (props: {activeTemplateId: any; setTemplateId: (templateId: any) => void}) => {
  return (
    <div className={styles.ModalNav}>
      <h3>Choose Template</h3>
      {templateCategories.map(({ name, templatesList }) => {
        return (
          <NavigationCategory
            key={name}
            className={styles.ModalNavCategory}
            categoryName={name}
            templates={templatesList}
            setTemplateId={props.setTemplateId}
            activeTemplateId={props.activeTemplateId}
          />
        );
      })}
    </div>
  );
};
