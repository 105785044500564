import parentPath from "../path";

export const tagsPath = `${parentPath}/tags`;

export const tagsPageQueryParams = {
  PAGE: "page",
  PAGE_SIZE: "pageSize",
  SEARCH: "q",
} as const;

export const getLinkToTagsPage = (args?: {
  query?: string
  page?: number
  pageSize?: number
}) => {
  if (!args) return tagsPath;
  const searchParams = new URLSearchParams();
  if ("query" in args) {
    searchParams.set(tagsPageQueryParams.SEARCH, JSON.stringify(args.query!));
  }
  if ("page" in args) {
    searchParams.set(tagsPageQueryParams.PAGE, JSON.stringify(args.page!));
  }
  if ("pageSize" in args) {
    searchParams.set(tagsPageQueryParams.PAGE_SIZE, JSON.stringify(args.pageSize!));
  }
  const getParamsString = searchParams.toString();

  if (getParamsString) {
    return `${tagsPath}?${getParamsString}`;
  }

  return tagsPath;
};

export default tagsPath;
