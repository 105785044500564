import React from "react";
import { Flex } from "@gs/uiKit/layout/Flex";

type OtpProps = {
  value: string
  onChange: (v: string) => void
  className?: string
  size?: "middle" | "small"
}

// TODO: allow uncontrolled input (value/onChange - optional)
export const Otp = (props: OtpProps) => {
  const length = 6;
  const factor = props.size === "small" ? 0.81 : 1;

  return (
    <Flex
      justify="space-between"
      className={props.className}
    >
      {new Array(length).fill(true).map((_, index) => (
        <input
          value={(!props.value[index] || props.value[index] === " " ) ? "" : props.value[index]}
          onChange={(e) => {
            const arrValue: string[] = [];
            for (let i = 0; i < length; i++) {
              arrValue[i] = props.value[i] || " ";
            }
            arrValue[index] = e.target.value.replace(arrValue[index]!, "");
            props.onChange(arrValue.join(""));
            if (e.target.value) {
              // @ts-ignore
              e.target.nextElementSibling?.focus?.();
            } else {
              // @ts-ignore
              // e.target.previousElementSibling?.focus?.();
            }
          }}
          onKeyDown={(e) => {
            if (e.code === "Backspace") {
              // @ts-ignore
              e.target.previousElementSibling?.focus?.();
            }
          }}
          onPaste={(e) => {
            e.preventDefault();
            const text = e.clipboardData.getData("Text");

            props.onChange(text.slice(0, length));
          }}
          style={{
            width: 77 * factor,
            height: 77 * factor,
            fontSize: 32 * factor,
            textAlign: "center",
            border: `${1.22 * factor}px solid #E6E9EE`,
            borderRadius: 22 * factor,
          }}
        />
      ))}
    </Flex>
  );
};
