import { createCurrentUserApi } from "@gs/core/domain/User/createCurrentUserApi";
import { getBaseAxios } from "services/axios";
import { userStore } from "domain/user/userStore";


export const currentUserApi = (() => {
  const axiosInstance = getBaseAxios("idV2");

  const coreApi = createCurrentUserApi(axiosInstance);

  const getCurrentUser: typeof coreApi.getCurrentUser = async (...args) => {
    const response = await coreApi.getCurrentUser(...args);

    userStore.setState({
      [response.data.id]: response.data,
    });

    return response;
  };

  const changeEmail: typeof coreApi.changeEmail = async (...args) => {
    const response = await coreApi.changeEmail(...args);

    getCurrentUser(); // to update user in store

    return response;
  };

  return {
    getCurrentUser,
    updateCurrentUser: coreApi.updateCurrentUser,
    signIn: coreApi.signIn,
    signUp: coreApi.signUp,
    signOut: coreApi.signOut,
    sendResetPasswordEmail: coreApi.sendResetPasswordEmail,
    resetPassword: coreApi.resetPassword,
    changePassword: coreApi.changePassword,
    changeEmail,
    setLastTeamId: coreApi.setLastTeamId,
  };
})();
