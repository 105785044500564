import type { Plan, PlanId } from "@gs/core/domain/Plan/Plan";


export const testPlanId = "786935" as PlanId;

export type PlanInfo = {
  description: string
  monthlyPlanId: PlanId
  yearlyPlanId: PlanId
}

export type FlexPlanInfo = {
  description: string
  monthlyPlanId: PlanId
}

export const soloPlansInfo: PlanInfo = {
  description: "Keep your account safe, automation smooth, and GTM on track",
  monthlyPlanId: "906880" as PlanId,
  yearlyPlanId: "906906" as PlanId,
};

export const growPlansInfo: PlanInfo = {
  description: "Keep your account safe, automation smooth, and GTM on track",
  monthlyPlanId: "906881" as PlanId,
  yearlyPlanId: "906907" as PlanId,
};

export const business10PlansInfo: PlanInfo = {
  description: "Keep your account safe, automation smooth, and GTM on track",
  monthlyPlanId: "906882" as PlanId,
  yearlyPlanId: "906908" as PlanId,
};

export const business20PlansInfo: PlanInfo = {
  description: "Keep your account safe, automation smooth, and GTM on track",
  monthlyPlanId: "906883" as PlanId,
  yearlyPlanId: "906909" as PlanId,
};

export const business50PlansInfo: PlanInfo = {
  description: "Keep your account safe, automation smooth, and GTM on track",
  monthlyPlanId: "906884" as PlanId,
  yearlyPlanId: "906910" as PlanId,
};

export const business100PlansInfo: PlanInfo = {
  description: "Keep your account safe, automation smooth, and GTM on track",
  monthlyPlanId: "906885" as PlanId,
  yearlyPlanId: "906911" as PlanId,
};

export const flex10PlansInfo: FlexPlanInfo = {
  description: "Keep your account safe, automation smooth, and GTM on track",
  monthlyPlanId: "907234" as PlanId,
};

export const flex50PlansInfo: FlexPlanInfo = {
  description: "Keep your account safe, automation smooth, and GTM on track",
  monthlyPlanId: "907394" as PlanId,
};

export const flex100PlansInfo: FlexPlanInfo = {
  description: "Keep your account safe, automation smooth, and GTM on track",
  monthlyPlanId: "907395" as PlanId,
};

export const flexUnlimitedPlansInfo: FlexPlanInfo = {
  description: "Keep your account safe, automation smooth, and GTM on track",
  monthlyPlanId: "907396" as PlanId,
};

export const flexPlansInfo: FlexPlanInfo[] = [
  flex10PlansInfo,
  flex50PlansInfo,
  flex100PlansInfo,
  flexUnlimitedPlansInfo,
];

export const mainPlansInfo: PlanInfo[] = [
  soloPlansInfo,
  growPlansInfo,
  business10PlansInfo,
  business20PlansInfo,
  business50PlansInfo,
  business100PlansInfo,
];

export const allMainPlanIds = [
  ...mainPlansInfo.flatMap((item) => [item.monthlyPlanId, item.yearlyPlanId]),
  ...flexPlansInfo.map((item) => item.monthlyPlanId),
];

export const getIsCustomPlanId = (planId: PlanId) => ![...allMainPlanIds, "trial"].includes(planId);

export const getIsPlanUpgradable = (planId: PlanId): boolean => {
  let index = mainPlansInfo.findIndex((item) => [item.yearlyPlanId, item.monthlyPlanId].includes(planId));
  if (index >= 0 && index < mainPlansInfo.length - 1) return true;

  index = flexPlansInfo.findIndex((item) => item.monthlyPlanId === planId);
  if (index >= 0 && index < flexPlansInfo.length - 1) return true;

  return false;
};

export const getIsPlanDowngradable = (planId: PlanId): boolean => {
  let index = mainPlansInfo.findIndex((item) => [item.yearlyPlanId, item.monthlyPlanId].includes(planId));
  if (index > 0) return true;

  index = flexPlansInfo.findIndex((item) => item.monthlyPlanId === planId);
  if (index > 0) return true;

  return false;
};
