import Loadable from "react-loadable";
import { Loading } from "components/common/Loading";
import { ModuleLoaderError } from "components/common/ModuleLoaderError";
import { injectReducer } from "store/reducers";
import store from "store/store";

const FlowDetailsPage = Loadable({
  loader: () => {
    const component = import(/* webpackChunkName:'FlowDetailsPage' */ "./PublicFlow");


    return import(/* webpackChunkName:'FlowDetailsPage' */ "../Details/reducer/reducer")
      .then( (reducer) => injectReducer(store, { key: "flowDetails", reducer: reducer.default }) )
      .then( () => component )
      .catch( (e) => console.log("Page loading err", e) || ModuleLoaderError );
    // return import(/* webpackChunkName:'FlowDetailsPage' */ './reducer/reducer')
    //   .then( reducer => injectReducer(store, { key: 'flowDetails', reducer: reducer.default }) )
    //   .then( () => component )
    //   .catch( e => console.log('Page loading err', e) || Error404 )
  },
  loading: Loading,
});

export default FlowDetailsPage;
