import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import { ModuleLoaderError } from "components/common/ModuleLoaderError";


export const AsyncStoplistsPage = Loadable({
  loader: () => {
    return import(/* webpackChunkName:'CustomFieldsPage' */ "./StoplistsPage")
      .catch( (e) => {
        console.log("Page loading err", e);
        return ModuleLoaderError;
      } );
  },
  // @ts-ignore
  loading: Loading,
});

export * from "./path";
export { default as path } from "./path";
export default AsyncStoplistsPage;

