import { Switch, Route, Redirect } from "react-router-dom";
import { flowPath } from "./path";
import { flowListPath, AsyncFlowListPage, getFlowListPageLink } from "./FlowListPage";
import { flowDetailsPath, AsyncFlowDetailsPage } from "./FlowDetailsPage";
import OldRoutes from "../OLD_flowsGroup/index";

export const FlowRoutes = () => {
  return (
    <Switch>
      <Route
        path={flowPath}
        exact
      >
        <Redirect to={getFlowListPageLink({ tab: "main" })} />
      </Route>
      <Route
        path={flowListPath}
        component={AsyncFlowListPage}
      />
      <Route
        path={flowDetailsPath}
        component={AsyncFlowDetailsPage}
        exact={true}
      />
      <Route
        component={OldRoutes}
      />
    </Switch>
  );
};
