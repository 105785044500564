import React from "react";
import { Route, Switch } from "react-router-dom";
import browserHistory from "services/browserHistory";
import store from "store/store";
import { proxyStatuses } from "store/proxy/consts";
import { setProxyServerId, setProxyStatus } from "store/proxy/actions";
import SalesNavigatorPeopleRoute, { path as salesNavigatorPeoplePath } from "./SalesNavigatorPeople";
import SalesNavigatorCompaniesRoute, { path as salesNavigatorCompaniesPath } from "./SalesNavigatorCompanies";
import SalesNavigatorPersonRoute, { path as salesNavigatorPersonPath } from "./SalesNavigatorPerson";
import SalesNavigatorListPeopleRoute, { path as salesNavigatorListPeoplePath } from "./SalesNavigatorListPeople";
import LinkedInPeopleRoute, { path as linkedInPeoplePath } from "./LinkedInPeople";
import LinkedInPersonRoute, { path as linkedInPersonPath } from "./LinkedInPerson";
import LinkedInCompaniesRoute, { path as linkedInCompaniesPath } from "./LinkedInCompanies";
import GroupMembersRoute, { path as groupMembersPath } from "./GroupMembers";
import LinkedinJobsRoute, { path as linkedinJobsPath } from "./LinkedinJobs";
import PluginTasksRoute, { path as pluginTasksPath } from "./PluginTasks";
import DefaultRoute, { path as defaultPath } from "./Default";
import NotFoundRoute from "../NotFound";
import {
  eventNames,
  messagesFromClientPluginToFrontApp, messagesFromFrontAppToClientPlugin,
} from "clientPluginCommunication";
import { tasksIsActiveSelector } from "../OLD__Tasks/reducer/selectors";
import LinkedInCompanyProfile, { path as linkedInCompaniesProfilePath } from "./LinkedInCompanyProfile";
import { sessionService } from "feature/session/SessionService";
import { sendMessageToPlugin } from "components/routes/PluginGroup/pluginEvents";

export { default as path } from "./path";

sessionService.store.subscribe((s) => s.teamId, (newTeamId) => {
  sendMessageToPlugin(
    messagesFromFrontAppToClientPlugin.SET_TEAM_ID_VALUE, { id: newTeamId },
  );
  sendMessageToPlugin(
    messagesFromFrontAppToClientPlugin.REMOVE_AUGMENT,
  );
});

document.body.addEventListener(eventNames.FROM_PLUGIN_EVENT_NAME, (event) => {
  console.log("BODY_EVENT", event.detail);
  localStorage.removeItem("_dynamicRecords");
  localStorage.removeItem("_dynamicContext");
  const state = store.getState();

  switch (event.detail.type) {
    case messagesFromClientPluginToFrontApp.RESTART: {
      console.log("restart");
      if (tasksIsActiveSelector(state)) {
        break;
      }

      browserHistory.push(event.detail.url);
      break;
    }
    case messagesFromClientPluginToFrontApp.SET_TEAM_ID: {
      localStorage.setItem("list", null);
      localStorage.setItem("tags", []);
      sessionService.changeTeam(event.detail.id);
      break;
    }
    case messagesFromClientPluginToFrontApp.RECORDS: {
      if (tasksIsActiveSelector(state)) {
        break;
      }

      localStorage.setItem("_dynamicRecords", JSON.stringify(event.detail.records));
      break;
    }
    case messagesFromClientPluginToFrontApp.SET_ROUTE: {
      browserHistory.push(event.detail.route);
      break;
    }
    case messagesFromClientPluginToFrontApp.DYNAMIC_DATA: {
      if (event.detail.route != window.location.pathname) {
        localStorage.setItem("_dynamicRecords", JSON.stringify(event.detail.data));
        localStorage.setItem("_dynamicContext", event.detail.context);
        browserHistory.push(event.detail.route);
      }}
      break;
    case messagesFromClientPluginToFrontApp.NEUTRAL_PROFILE_PROXY: {
      store.dispatch(setProxyStatus(proxyStatuses.NEUTRAL));
      break;
    }
    case messagesFromClientPluginToFrontApp.WRONG_PROFILE_PROXY: {
      store.dispatch(setProxyStatus(proxyStatuses.ERROR));
      if (event.detail.serverId) {
        store.dispatch(setProxyServerId(event.detail.serverId));
      }
      break;
    }
    case messagesFromClientPluginToFrontApp.RIGHT_PROFILE_PROXY: {
      store.dispatch(setProxyStatus(proxyStatuses.OK));
      if (event.detail.serverId) {
        store.dispatch(setProxyServerId(event.detail.serverId));
      }
      break;
    }
  }
});

export default () => (
  <Switch>
    <Route
      path={salesNavigatorPeoplePath}
      component={SalesNavigatorPeopleRoute}
    />
    <Route
      path={salesNavigatorCompaniesPath}
      component={SalesNavigatorCompaniesRoute}
    />
    <Route
      path={salesNavigatorPersonPath}
      component={SalesNavigatorPersonRoute}
    />
    <Route
      path={salesNavigatorListPeoplePath}
      component={SalesNavigatorListPeopleRoute}
    />
    <Route
      path={linkedInPeoplePath}
      component={LinkedInPeopleRoute}
    />
    <Route
      path={linkedInPersonPath}
      component={LinkedInPersonRoute}
    />
    <Route
      path={linkedInCompaniesPath}
      component={LinkedInCompaniesRoute}
    />
    <Route
      path={linkedInCompaniesProfilePath}
      component={LinkedInCompanyProfile}
    />
    <Route
      path={groupMembersPath}
      component={GroupMembersRoute}
    />
    <Route
      path={linkedinJobsPath}
      component={LinkedinJobsRoute}
    />
    <Route
      path={pluginTasksPath}
      component={PluginTasksRoute}
    />
    <Route
      path={defaultPath}
      component={DefaultRoute}
    />

    {/* Этот роут должен быть в конце свитча, иначе он все перехватит и управление
      не дойдет до остальных роутов */}
    <Route component={NotFoundRoute} />
    {" "}
    {/* 404 */}
  </Switch>
);


