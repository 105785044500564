import * as actions from "./consts";

const ACTION_HANDLERS = {
  [actions.FLOW_SOURCE_STATISTICS_SET]: (state, { statistics }) => ({
    ...state,
    sourceStatistics: statistics,
  }),
  [actions.FLOW_NODES_STATISTICS_SET]: (state, { statistics }) => ({
    ...state,
    nodesStatistics: {
      ...state.nodesStatistics,
      ...statistics,
    },
  }),
};

const initialState = {};

const flowStatisticsReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export {
  flowStatisticsReducer,
};
