import Loadable from "react-loadable";
import { ModuleLoaderError } from "components/common/ModuleLoaderError";

const Loading = () => <div style={{ height: 300, width: "100%" }}></div>;

export const AsyncWhiteLabelBg = Loadable({
  loader: () => {
    return import(/* webpackChunkName:'AsyncWhiteLabelBg' */ "./assets/whiteLabelBg.svg")
      .then((res) => res.ReactComponent)
      .catch( () => ModuleLoaderError);
  },
  // @ts-ignore
  loading: Loading,
});


