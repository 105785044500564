import { path as apiKeysPath, AsyncApiKeysPage } from "./ApiKeys";
import { path as tagsPath, AsyncTagsPage } from "./Tags";
import { path as customFieldsPath, AsyncCustomFieldsPage } from "./CustomFields";
import { stoplistsPath, AsyncStoplistsPage } from "./Stoplists";
import WebhooksPage, { path as webhooksPath } from "./Webhooks";
import LinkedinFilters, { path as linkedinFiltersPath } from "./LinkedinFilters";
import TeamMembers, { path as teamMembersPath } from "./Team";
import PipelineStages, { path as pipelineStagesPath } from "./PipelineStages";
import type { ComponentType } from "react";

type RouteDescriptions = {
  path: string // e.g. /stages/:id used to highlight menu item as `active`
  to?: string // e.g. /stages or /stages/123. direct link without variables. if empty than `path` will be used (should be NOT dynamic)
  label: string
  body: ComponentType
  subRoutes?: RouteDescriptions[]
}

export const settingsSubRoutes: RouteDescriptions[] = [
  {
    path: teamMembersPath,
    label: "Team Members",
    body: TeamMembers,
  },
  {
    path: customFieldsPath,
    label: "Custom Fields",
    body: AsyncCustomFieldsPage,
  },
  {
    path: tagsPath,
    label: "Tags",
    body: AsyncTagsPage,
  },
  {
    path: pipelineStagesPath,
    label: "Pipeline Stages",
    body: PipelineStages,
  },
  {
    path: webhooksPath,
    label: "Webhooks",
    body: WebhooksPage,
  },
  {
    path: apiKeysPath,
    label: "API Keys",
    body: AsyncApiKeysPage,
  },
  {
    path: linkedinFiltersPath,
    label: "LinkedIn Filters",
    body: LinkedinFilters,
  },
  {
    path: stoplistsPath,
    label: "Stoplist",
    body: AsyncStoplistsPage,
  },
];
