import * as actions from "./consts";
import * as contactsActions from "../contacts/consts";
import find from "lodash/find";
import getAxios from "services/axios";
import forEach from "lodash/forEach";
import { createCachedFunction } from "../utils";
import store from "store/store";
import { getContactById } from "../contacts/selectors";
import { getFlowById } from "./selectors";
import { getNodeTypeByAction } from "components/routes/OLD_flowsGroup/Flow/Details/reducer/utils/description";
import { ACTIVE_FLOWS_URL_FILTER, flowStatuses } from "./types";
import { loadFlowMetrics, loadFlowsSenderProfiles } from "../flowMetrics";
import { loadLeadByUuid } from "../leads/actions";
import { encodeToBase64 } from "../../../services/utils";
import { updateFlowWorkspacesMetrics } from "../flowWorkspaces/actions";
import { FDP_PARSE } from "components/routes/OLD_flowsGroup/Flow/Details/reducer/consts";


const axios = getAxios("flowV2");

export const loadFlowEnums = createCachedFunction(
  () => axios.get("/api/system/config"),
).cachedFunction;

export const loadNodes = createCachedFunction(() => (dispatch, getState) => {
  return axios.get("/api/flows/nodes")
    .then((res) => res.flow_nodes);
}, { recursively: true, clearTimer: 30000 })
  .cachedFunction;

const cacher = createCachedFunction((params, cancelToken) => (dispatch, getState) => {
  return axios.get("/api/flows", {
    cancelToken,
    params,
  })
    .then((res) => {
      dispatch({
        type: actions.FLOWS_LOAD_SUCCESS,
        flows: res.data,
      });

      return res;
    });
}, { recursively: true });

// export const loadFlows = cacher.cachedFunction;

export const loadFlows = (params) => (dispatch) => {
  let ids = [];
  return axios.get("/api/flows", {
    params,
  })
    .then((res) => {
      const { data } = res;
      data.forEach((element) => {
        ids.push(element.uuid);
      });
      dispatch({
        type: actions.FLOWS_LOAD_SUCCESS,
        flows: data,
      });
      if (ids.length > 0) {
        dispatch(loadFlowMetrics(ids));
        dispatch(loadFlowsSenderProfiles(ids));
      }

      return res;
    });
};

export const loadFlowsList = (params) => (dispatch) => {
  return axios.post("/api/flows/list", { params })
    .then((res) => {
      const { data } = res;
      dispatch({
        type: actions.FLOWS_LOAD_SUCCESS,
        flows: data,
      });
      return res;
    });
};

export const loadFlowLeadsByLeadId = (id, params) => async (dispatch) => {
  const res = await axios.post("/api/flows/leads/get-by-lead-uuids", {
    lead_uuids: [id],
  });

  return res[id];
};

export const loadFlow = (id) => async (dispatch) => {
  const flow = await axios.get(`/api/flows/${id}`);
  const version = await loadFlowVersion(flow.flow_version_uuid);
  flow.nodes = version.nodes.map((node) => {
    const newNode = { ...node };
    if (newNode.automation) {
      newNode.payload.automation = newNode.automation;
    }
    return {
      ...newNode,
      action: newNode.type,
      type: getNodeTypeByAction(newNode.type),
    };
  });
  flow.version = version;

  // transformSteps(flow);
  // setFirstId(flow);

  dispatch({
    type: actions.FLOW_LOAD_SUCCESS,
    flow,
  });
  return flow;
};

export const saveFlowVersion = (flowId, { nodes, contact_sources, first_common_node_id }) => (dispatch) => {
  return axios.post(`/api/flows/${flowId}/flow-versions/`, {
    nodes,
    contact_sources,
    first_common_node_id,
  });
};


export const loadPublicFlow = (flowPublicId, clusterId) => (dispatch) => {
  const config = {
    clusterId,
  };

  return axios.get(`/api/flows/share/${flowPublicId}`, config).then((flow) => {
    return axios.get(`/api/flow-versions/${flow.flow_version_uuid}/public`, config).then((flowVersion) => {

      flow.nodes = flowVersion.nodes.map((node) => {
        const newNode = { ...node };
        if (newNode.automation) {
          newNode.payload.automation = newNode.automation;
        }
        return {
          ...newNode,
          action: newNode.type,
          type: getNodeTypeByAction(newNode.type),
        };
      });
      // flow.contact_sources = flowVersion.contact_sources;
      // flow.first_common_node_id = flowVersion.first_common_node_id;
      flow.version = flowVersion;

      dispatch({
        type: FDP_PARSE,
        flow,
      });

      return flow;
    });
  });
};

export const copyFlow = ({ name, nodes }) => (dispatch) => {
  return axios.put("/api/flows/duplicate", { name, nodes })
    .then((flow) => {
      dispatch({
        type: actions.FLOW_LOAD_SUCCESS,
        flow,
      });

      return flow;
    });
};

export const cancelLeadFromFlow = (flowId, leadId) => (dispatch) => {
  return axios.put(`/api/flows/leads/${leadId}/cancel`, { flow_uuids: [flowId] })
    .then(() => {
      dispatch(loadLeadByUuid(leadId));
    });
};

export const createPublicLink = (id) => (dispatch) => {
  return axios.put(`/api/flows/${id}/share-link`)
    .then((flow) => {
      dispatch({
        type: actions.FLOW_LOAD_SUCCESS,
        flow,
      });

      return flow;
    });
};

export const getFlowOptions = (query) => {
  const flows = Object.values(store.getState().entities.flows);
  return Promise.resolve(flows.length ? { data: flows } : store.dispatch(loadFlows({})))
    .then(({ data }) => data.reduce((res, flow) => {
      if (RegExp(query, "i").test(flow.name) || typeof query !== "string") {
        res.push({ value: flow.uuid, label: flow.name });
      }

      return res;
    }, []),
    );
};

export const getFlowActiveOptions = (query, params = {}) => {
  const flows = Object.values(store.getState().entities.flows);
  return Promise.resolve(flows.length >= 2 ? { data: flows } : store.dispatch(loadFlows({ // Костыль
    ...params,
    filter: encodeToBase64({ status: ["on", "off"], ...params?.filter }),
  })))
    .then(({ data }) => data.reduce((res, flow) => {
      if (RegExp(query, "i").test(flow.name) || typeof query !== "string") {
        res.push({ value: flow.uuid, label: flow.name });
      }
      return res;
    }, []),
    );
};

export const getFlowOptionsWithAllOption = async (query) => {
  let result = [];
  await store.dispatch(loadFlows({ limit: 100 })).then(({ data }) => {
    result = data.reduce((res, flow) => {
      if (RegExp(query, "i").test(flow.name) || typeof query !== "string") {
        res.push({ value: flow.uuid, label: flow.name, status: flow.status });
      }
      return res;
    }, []);
    const archived = result.filter((opt) => opt.status === "archived");
    const running = result.filter((opt) => opt.status === "on");
    const paused = result.filter((opt) => opt.status === "off");
    result = [
      { value: null, label: "All automations" },
      {
        label: "running",
        options: [...running],
      },
      {
        label: "paused",
        options: [...paused],
      },
      {
        label: "archived",
        options: [...archived],
      },
      { value: "is_null", label: "No automations" },
    ];

  },
  );
  return result;
};

export const getSourceOptions = (flowId) => store.dispatch(loadFlow(flowId))
  .then((flow) => {
    return flow.version.contact_sources.map((item) => ({ value: item.id, label: `#${item.id}` }));
  });

export const loadFlowVersion = (flowVersionId) => {
  return axios.get(`/api/flow-versions/${flowVersionId}`);
};

export const loadFlowVersions = (flowId) => {
  return axios.get(`/api/flows/${flowId}/flow-versions/`);
};

export const createFlow = ({ params }) => async (dispatch, getState) => {
  const flow = await axios.post("/api/flows", params);
  const version = await loadFlowVersion(flow.flow_version_uuid);
  flow.nodes = version.node;
  flow.version = version;
  const chosenWorkspace = getState().flowList?.filters?.flow_workspace_uuid;
  cacher.clearCache();
  window.dataLayer.push({ "event": "flow-process-created" });
  dispatch({
    type: actions.FLOW_CREATE_SUCCESS,
    flow,
    chosenWorkspace,
  });
  dispatch(updateFlowWorkspacesMetrics());

  return flow;
};

export const moveStep = (flowId, nodeId, { before, after, version }) => (dispatch) => {
  return axios.put(`/api/flows/${flowId}/nodes/${nodeId}/move`, { before, after, version })
    .then((step) => {
      dispatch({
        type: actions.FLOW_STEP_NEW_SUCCESS,
        flowId,
        step,
      });

      return step;
    });
};

export const createStep = (flowId, data) => (dispatch, getState) => {
  return axios.post(`/api/flows/${flowId}/nodes`, data)
    .then((step) => {
      dispatch({
        type: actions.FLOW_STEP_NEW_SUCCESS,
        flowId,
        step,
      });

      return step;
    });
};

export const editStep = (flowId, stepId, data) => (dispatch, getState) => {
  dispatch({
    type: actions.FLOW_STEP_EDIT_REQUEST,
    flowId,
    stepId,
    data,
  });

  const oldData = find(getState().entities.flows[flowId].nodes, { id: stepId });

  return axios.put(`/api/flows/${flowId}/nodes/${stepId}`, data)
    .then((step) => {
      dispatch({
        type: actions.FLOW_STEP_EDIT_SUCCESS,
        step,
      });

      return step;
    })
    .catch((error) => {
      dispatch({
        type: actions.FLOW_STEP_EDIT_FAILED,
        flowId,
        stepId,
        oldData,
      });

      return Promise.reject(error);
    });
};

export const updateFlow = ({ uuid, params }) => (dispatch) => {
  return axios.put(`/api/flows/${uuid}`, params)
    .then((flow) => {
      cacher.clearCache();
      dispatch({
        type: actions.FLOW_UPDATE_SUCCESS,
        flow,
      });

      return flow;
    });
};

export const archiveFlow = ({ uuid }) => (dispatch) => {
  return axios.put(`/api/flows/${uuid}/archive`)
    .then((flow) => {
      cacher.clearCache();
      dispatch({
        type: actions.FLOW_UPDATE_SUCCESS,
        flow,
      });

      return flow;
    });
};

export const unarchiveFlow = ({ uuid }) => (dispatch) => {
  return axios.put(`/api/flows/${uuid}/unarchive`)
    .then((flow) => {
      cacher.clearCache();
      dispatch({
        type: actions.FLOW_UPDATE_SUCCESS,
        flow,
      });

      return flow;
    });
};

export const cloneFlow = ({ flowVersionUuid, flowData, isTemplate }) => async (dispatch, getState) => {
  const flowVersion = isTemplate ? flowData.version : await loadFlowVersion(flowVersionUuid);
  const chosenWorkspace = getState().flowList?.filters?.flow_workspace_uuid;
  const editedContactSources = flowVersion.contact_sources.map((source) => {
    return { ...source, mass_actions_filter: null, sender_profiles: [], filter_tree_format: [] };
  });
  const payload = {
    flow_workspace_uuid: flowData.flow_workspace_uuid,
    name: flowData.name,
    schedule: flowData.schedule,
    nodes: flowVersion.nodes,
    contact_sources: editedContactSources,
    first_common_node_id: flowVersion.first_common_node_id,
    use_sender_schedule: false,
  };
  if (isTemplate) {
    payload.nodes = payload.nodes.map((node) => ({
      ...node,
      type: node.action || "end",
      action: undefined,
    }));
  }
  const flow = await axios.post("/api/flows/clone", payload);
  await dispatch(loadFlowMetrics([flow.uuid]));
  await dispatch(loadFlowsSenderProfiles([flow.uuid]));
  // dispatch({
  //   type: actions.FLOW_CREATE_SUCCESS,
  //   flow,
  //   chosenWorkspace,
  // });
  dispatch(updateFlowWorkspacesMetrics());

  // return axios.get(`/api/flow-versions/${flowVersionUuid}`)
  //   .then( flowVersion => {
  // cacher.clearCache();
  // dispatch({
  //   type: actions.FLOW_UPDATE_SUCCESS,
  //   flow,
  // });

  return flow;
};


export const deleteFlow = (flowId) => (dispatch) => {
  return axios.delete(`/api/flows/${flowId}`)
    .then(() => {
      dispatch({
        type: actions.FLOW_DELETE_SUCCESS,
        flowId,
      });
      dispatch(updateFlowWorkspacesMetrics());
    },

    );
};

export const deleteStep = (flowId, stepId) => (dispatch, getState) => {
  const flow = getState().entities.flows[flowId];
  const after = find(flow.nodes, { id: stepId }).after;
  let after_branch_id;
  if (after.length) {
    after_branch_id = after[after.length - 1].branch_id;
  }

  return axios.delete(`/api/flows/${flowId}/nodes/${stepId}`, { params: { after_branch_id, version: flow.version } })
    .then((step) => dispatch({
      type: actions.FLOW_STEP_DELETE_SUCCESS,
      step,
    }));
};

export const startFlow = (flowId) => (dispatch) => {
  return axios.put(`/api/flows/${flowId}/start`, {
    status: flowStatuses.ON,
  })
    .then((res) => {
      window.dataLayer.push({ "event": "flow-process-started" });
      dispatch({
        type: actions.FLOW_STATUS_CHANGE_SUCCESS,
        status: flowStatuses.ON,
        flowId,
      });
    });
};

export const stopFlow = (flowId) => (dispatch) => {
  return axios.put(`/api/flows/${flowId}/stop`, {
    status: flowStatuses.OFF,
  })
    .then((res) => {
      dispatch({
        type: actions.FLOW_STATUS_CHANGE_SUCCESS,
        status: flowStatuses.OFF,
        flowId,
      });
    });
};

/////////// utils ///////////////
const setFirstId = (flow) => {
  forEach(flow.nodes, (step) => {
    if (flow.firstId) return;

    if (!step.before.length) {
      flow.firstId = step.id;
    }
  });

  if (!flow.firstId) {
    throw "Invalid flow steps: No firstId";
  }
};

const transformSteps = (flow) => {
  flow.nodes = flow.nodes.reduce((res, step) => {
    res[step.id] = step;
    return res;
  }, {});
};

const setContactsToFlow = ({ filter, flow, sync, include_coworkers }) => (dispatch, getState) => {
  const state = getState();
  const flowItem = getFlowById(state, flow);

  const contacts = filter.ids.map((id) => {
    const contact = getContactById(state, { id });

    if (contact && Array.isArray(contact.flows) && flow) {
      contact.flows.push(flowItem);
    }

    return contact;
  });

  return axios.put(`/api/flows/${flow}/leads`, { filter, flow, sync, include_coworkers })
    .then(() => dispatch({
      type: contactsActions.SEED_CONTACTS,
      contacts,
    }));
};

const deleteContactsFromFlow = ({ filter, flow, include_coworkers }) => (dispatch, getState) => {
  const contacts = filter.ids.map((id) => {
    const contact = { ...getContactById(getState(), { id }) };

    if (contact && Array.isArray(contact.flows)) {
      const theFlow = contact.flows.find((theFlow) => flow == theFlow.id);
      if (theFlow) {
        theFlow.status = "cancelled";
      }
      // contact.flows = contact.flows.filter((flowItem) => flowItem.id !== flow);
    }

    return contact;
  });

  return axios.delete(`/api/flows/${flow}/leads`, { data: { filter, flow, include_coworkers } })
    .then(() => dispatch({
      type: contactsActions.SEED_CONTACTS,
      contacts,
    }));
};

export {
  setContactsToFlow,
  deleteContactsFromFlow,
};
