import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import { __DEV__ } from "services/env";
import { ModuleLoaderError } from "components/common/ModuleLoaderError";

export { playgroundPath } from "./path";

export const PlaygroundAsyncPage = Loadable({
  loader: () => {
    const componentPromise = __DEV__
      ? import(/* webpackChunkName: 'Playground' */ "./Playground")
      : Promise.resolve(Error404);

    return componentPromise
      .catch( (e) => console.log("Page loading err", e) || ModuleLoaderError );
  },
  loading: Loading,
});

export default PlaygroundAsyncPage;
