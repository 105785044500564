import type { StateCreator } from "zustand";
import { useStore } from "zustand";
import { subscribeWithSelector, devtools } from "zustand/middleware";
import type { StoreApi } from "zustand/esm/vanilla";
import { createStore as _createStore } from "zustand/vanilla";
import { __DEV__ } from "services/env";

export const createStore = <S>(name: string, cb: StateCreator<S> ) => _createStore<S>()(
  subscribeWithSelector(
    (__DEV__ ? devtools(cb, { name: "@gs/zus", store: name }) as StateCreator<S> : cb),
  ),
);

export type GsStoreApi<S> = ReturnType<typeof createStore<S>>
// export type GsStoreApi<T> = Omit<StoreApi<T>, "subscribe"> & {
//   subscribe: {
//     (listener: (selectedState: T, previousSelectedState: T) => void): () => void
//     <U>(selector: (state: T) => U, listener: (selectedState: U, previousSelectedState: U) => void, options?: {
//       equalityFn?: (a: U, b: U) => boolean
//       fireImmediately?: boolean
//     }): () => void
//   }
// }

type ExtractState<S> = S extends { getState: () => infer X } ? X : never
export const createBoundedUseStore = ((store) => (selector, equals) =>
  useStore(store, selector as never, equals)) as <S extends StoreApi<unknown>>(
  store: S,
) => {
  (): ExtractState<S>
  <T>(
    selector: (state: ExtractState<S>) => T,
    equals?: (a: T, b: T) => boolean,
  ): T
};


/*
// usage example

type MyState = {
  count: number
  isOld: boolean
  inc: () => void
}

const myStore = createStore<MyState>("my", (set) => ({
  count: 1,
  score: 23,
  isOld: !!1,
  inc: () => set((state) => ({ count: state.count + 1 })),
}));

myStore.setState({ count: 100_000_000_000_000 });
myStore.setState({ isOld: false });

const useMyStore = createBoundedUseStore(myStore);

function Counter() {
  const { isOld } = useMyStore();

  return null;
}

 */
