import { getBaseAxios } from "services/axios";
import { userStore } from "./userStore";
import { createUserApi } from "@gs/core/domain/User/createUserApi";
import type { UserId, User } from "@gs/core/domain/User/User";

export const userApi = (() => {
  const axiosInstance = getBaseAxios("idV2");

  const coreApi = createUserApi(axiosInstance);

  const getUsers: typeof coreApi.getUsers = async (...args) => {
    const response = await coreApi.getUsers(...args);

    userStore.setState(
      response.data.data.reduce<Record<UserId, User>>((acc, user) => {
        acc[user.id] = user;
        return acc;
      }, {}),
    );

    return response;
  };

  const updateUser: typeof coreApi.updateUser = async (...args) => {
    const response = await coreApi.updateUser(...args);

    userStore.setState({
      [response.data.id]: response.data,
    });

    return response;
  };

  const confirmEmail: typeof coreApi.confirmEmail = async (...args) => {
    const response = await coreApi.confirmEmail(...args);

    // if uncomment than check correct redirect after confirmation (to /dashboard/get-started)
    // userStore.setState({
    //   [response.data.id]: response.data,
    // });

    return response;
  };

  return {
    getUsers,
    updateUser,
    confirmEmail,
    resendConfirmationEmail: coreApi.resendConfirmationEmail,
  };
})();
