import { matchPath } from "react-router";
import basePath from "../path";
import type { CustomFieldObject } from "@gs/core/domain/CustomField/CustomFieldObject";

export const customFieldsPath = `${basePath}/custom-fields`;
export const objectParamName = "object";
export const customFieldsByObjectPath = `${customFieldsPath}/:${objectParamName}`;

export const getObjectParamValue = () => {
  const paramValue = matchPath<any>(window.location.pathname, customFieldsByObjectPath)?.params?.[objectParamName];
  if (!paramValue) return null;
  return getCustomFieldObjectByUrlParam(paramValue);
};

export const customFieldObjectUrlParamMap = {
  lead: "contact",
  company: "account",
  sender_profile: "sender-profile",
} as const satisfies Record<CustomFieldObject, string>;

export const getCustomFieldObjectByUrlParam = (urlParam: string): CustomFieldObject | null => {
  for (const [theCustomFieldObject, theUrlParam] of Object.entries(customFieldObjectUrlParamMap)) {
    if (theUrlParam === urlParam) return theCustomFieldObject as CustomFieldObject;
  }

  return null;
};

export const customFieldsPageQueryParams = {
  PAGE: "page",
  PAGE_SIZE: "pageSize",
  SEARCH: "q",
} as const;

export const getLinkToCustomFieldsPage = (args?: {
  customFieldObject?: CustomFieldObject
}) => {
  // TODO: use customFieldsPageQueryParams
  if (!args) return customFieldsPath;
  if (!args.customFieldObject) return customFieldsPath;

  return customFieldsByObjectPath.replace(new RegExp(`:${objectParamName}\\b`), customFieldObjectUrlParamMap[args.customFieldObject]);
};

export default customFieldsPath;
