import { getBaseAxios } from "services/axios";
import { flowStore } from "./flowStore";
import { createFlowApi } from "@gs/core/domain/Flow/createFlowApi";
import type { Flow, FlowId } from "@gs/core/domain/Flow/Flow";
import withMemo from "@eabgaryan/with-memo";
import stableStringify from "fast-json-stable-stringify";
import { createDomainBatchFetcher } from "domain/_utils/createDomainBatchFetcher";
import { dataBus } from "services/dataBus";

export const flowApi = (() => {
  const leadsAxios = getBaseAxios("flowV2");

  const coreFlowApi = createFlowApi(leadsAxios);

  const getFlows: typeof coreFlowApi.getFlows = async (...args) => {
    const result = await coreFlowApi.getFlows(...args);

    flowStore.setState(
      result.data.data.reduce<Record<FlowId, Flow>>((acc, flow) => {
        acc[flow.uuid] = flow;
        return acc;
      }, {}),
    );

    return result;
  };

  const getFlowsMemoized = withMemo(
    getFlows,
    {
      ttl: 10_000,
      getKey: stableStringify,
    },
  );

  const getFlow: typeof coreFlowApi.getFlow = async (...args) => {
    const result = await coreFlowApi.getFlow(...args);
    flowStore.setState({
      [result.data.uuid]: result.data,
    });

    return result;
  };

  const getFlowMetrics: typeof coreFlowApi.getFlowMetrics = async (...args) => {
    const result = await coreFlowApi.getFlowMetrics(...args);

    return result;
  };

  const getFlowMetricsBatched = createDomainBatchFetcher(
    (flowIds: FlowId[]) => getFlowMetrics({ flowIds }),
  );

  const getAllSenderProfilesForFlows: typeof coreFlowApi.getAllSenderProfilesForFlows = async (...args) => {
    const result = await coreFlowApi. getAllSenderProfilesForFlows(...args);

    return result;
  };

  const getAllSenderProfilesForFlowsBatched = createDomainBatchFetcher(
    (flowIds: FlowId[]) => getAllSenderProfilesForFlows({ flowIds }),
  );

  const startFlow: typeof coreFlowApi.startFlow = async (...args) => {
    const result = await coreFlowApi.startFlow(...args);

    flowStore.setState({
      [result.data.uuid]: result.data,
    });

    dataBus.emit("flow:updated", { flowIds: [result.data.uuid] });

    return result;
  };

  const stopFlow: typeof coreFlowApi.stopFlow = async (...args) => {
    const result = await coreFlowApi.stopFlow(...args);

    flowStore.setState({
      [result.data.uuid]: result.data,
    });

    dataBus.emit("flow:updated", { flowIds: [result.data.uuid] });

    return result;
  };

  const archiveFlow: typeof coreFlowApi.archiveFlow = async (...args) => {
    const result = await coreFlowApi.archiveFlow(...args);

    flowStore.setState({
      [result.data.uuid]: result.data,
    });

    dataBus.emit("flow:updated", { flowIds: [result.data.uuid] });

    return result;
  };

  const unarchiveFlow: typeof coreFlowApi.unarchiveFlow = async (...args) => {
    const result = await coreFlowApi.unarchiveFlow(...args);

    flowStore.setState({
      [result.data.uuid]: result.data,
    });

    dataBus.emit("flow:updated", { flowIds: [result.data.uuid] });

    return result;
  };

  const updateFlow: typeof coreFlowApi.updateFlow = async (...args) => {
    const result = await coreFlowApi.updateFlow(...args);

    flowStore.setState({
      [result.data.uuid]: result.data,
    });

    dataBus.emit("flow:updated", { flowIds: [result.data.uuid] });

    return result;
  };

  const createFlow: typeof coreFlowApi.createFlow = async (...args) => {
    const result = await coreFlowApi.createFlow(...args);

    flowStore.setState({
      [result.data.uuid]: result.data,
    });

    dataBus.emit("flow:created", { flowIds: [result.data.uuid] });

    return result;
  };

  const cloneFlow: typeof coreFlowApi.cloneFlow = async (...args) => {
    const result = await coreFlowApi.cloneFlow(...args);

    flowStore.setState({
      [result.data.uuid]: result.data,
    });

    dataBus.emit("flow:created", { flowIds: [result.data.uuid] });

    return result;
  };

  const makePublic: typeof coreFlowApi.makePublic = async (...args) => {
    const result = await coreFlowApi.makePublic(...args);

    flowStore.setState({
      [result.data.uuid]: result.data,
    });

    dataBus.emit("flow:updated", { flowIds: [result.data.uuid] });

    return result;
  };

  return {
    getFlows,
    getFlowsMemoized,
    getFlow,
    getFlowMetrics,
    getFlowMetricsBatched,
    getAllSenderProfilesForFlows,
    getAllSenderProfilesForFlowsBatched,
    startFlow,
    stopFlow,
    archiveFlow,
    unarchiveFlow,
    updateFlow,
    createFlow,
    cloneFlow,
    makePublic,
  };
})();
