import styleVars from "./vars.module.scss";

export const ySize = parseInt(styleVars.ySize);
export const xSize = parseInt(styleVars.xSize);
export const iconSize = parseInt(styleVars.iconSize);
export const topOffset = parseInt(styleVars.topOffset);
export const leftOffset = parseInt(styleVars.leftOffset);

export const branchColor = styleVars.branchColor;
export const actionColor = styleVars.actionColor;
export const triggerColor = styleVars.triggerColor;
export const ruleColor = styleVars.ruleColor;
export const startColor = styleVars.startColor;
export const endColor = styleVars.endColor;

export default {
  ySize,
  xSize,
  iconSize,
  topOffset,
  leftOffset,
};

export const getYpx = (y) => {
  return y - 2 ;
};
export const getXpx = (x, minX) => {
  return leftOffset + (x - minX) * xSize;
};
