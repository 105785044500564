import type { INavigationCategoryProps } from "../../../interfaces";
import cn from "classnames";

export const NavigationCategory = ({ categoryName, templates, className, setTemplateId, activeTemplateId }: INavigationCategoryProps) => {
  return (
    <div className={className}>
      <h4>{categoryName}</h4>
      {templates.map(({ label, uuid }) => {
        return (
          <span
            key={label}
            className={cn("nav-link gs-p-xxs", { unselected: activeTemplateId != uuid })}
            style={{
              cursor: "pointer",
              borderRadius: 4,
              backgroundColor: activeTemplateId == uuid ? "#CCEDF2" : "transparent",
            }}
            onClick={() => setTemplateId(uuid)}
          >
            {label}
          </span>
        );
      })}
    </div>
  );
};
