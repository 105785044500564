
type Listener<T> = (...args: T extends never ? [] : [data: T]) => void;

export class DataBus<EventTypesToData extends Record<string, unknown>> {
  private listeners: { [E in keyof EventTypesToData]?: Set<Listener<EventTypesToData[E]>> } = {};

  public subscribe<E extends keyof EventTypesToData>(eventType: E, listener: Listener<EventTypesToData[E]>) {
    if (!this.listeners[eventType]) {
      this.listeners[eventType] = new Set();
    }
    this.listeners[eventType]!.add(listener);

    return () => this.unsubscribe(eventType, listener);
  }

  public massSubscribe(eventTypes: Array<keyof EventTypesToData>, listener: () => void) {
    const unsubscribers = eventTypes.map((eventType) => this.subscribe(eventType, listener));

    return () => unsubscribers.forEach((unsubscriber) => unsubscriber());
  }

  public unsubscribe<E extends keyof EventTypesToData>(eventType: E, listener: Listener<EventTypesToData[E]>): void {
    if (!this.listeners[eventType]) return;
    this.listeners[eventType]!.delete(listener);
  }

  public emit<E extends keyof EventTypesToData>(
    eventType: E,
    // data: EventTypesToData[E],
    ...args: EventTypesToData[E] extends never ? [] : [data: EventTypesToData[E]]
  ): void {
    this.listeners[eventType]?.forEach((listener) => {
      try {
        listener(...args);
      } catch (error) {
        console.error(`Error in listener for event type '${String(eventType)}':`, error);
      }
    });
  }


  /*
  type Prefixes<T extends string> =
    T extends `${infer Head}:${infer Tail}`
      ? Head | `${Head}:${Prefixes<Tail>}`
      : T;
   */
  private getSubEvents(eventName: string): string[] {
    const substrings = eventName.split(":");
    const subEvents: string[] = [];

    for (let i = substrings.length; i >= 1; i--) {
      subEvents.push(substrings.slice(0, i).join(":"));
    }

    return subEvents;
  }
}
