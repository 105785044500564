import type { Mixed } from "../../commonTypes";
import type { PipelineStageId, PipelineStage } from "./PipelineStage";
import type { UserId } from "../User/User";

export type PipelineStageFilter = {
  query?: string
  object?: Mixed<PipelineStage["object"]>
  id?: Mixed<PipelineStageId>
  name?: Mixed<string>
  type?: Mixed<PipelineStage["type"]>
  category?: Mixed<PipelineStage["category"]>
  userId?: Mixed<UserId>
  withDeleted?: boolean
}

export const pipelineStageFilterToServerFormat = (filter?: PipelineStageFilter) => filter && ({
  q: filter.query,
  object: filter.object,
  uuid: filter.id,
  name: filter.name,
  type: filter.type,
  category: filter.category,
  user_id: filter.userId,
  deleted: filter.withDeleted,
});
