import styles from "./UnauthLayout.module.scss";
import Preloader from "components/common/elements/Preloader";
import { FullLogo } from "components/common/Logo/FullLogo";
import { useCurrentUser } from "feature/session/hook/useCurrentUser";
import browserHistory from "services/browserHistory";
import { Flex } from "@gs/uiKit/layout/Flex";
import type { ReactNode } from "react";
import { BgBadges } from "./BgBadges";
import ava1Base64 from "./assets/ava1.png";
import ava2Base64 from "./assets/ava2.png";
import { ReactComponent as AppsumoLogo } from "./appsumo.svg";
import { useRouteMatch } from "react-router-dom";
import { oauthPath } from "components/routes/authGroup/Oauth/path";
import { userConfirmationPath } from "components/routes/authGroup/UserConfirmation/path";
import { useSelector } from "react-redux";
import { getIsWhiteLabel, getWhiteLabelName } from "store/whiteLabel/selectors";
import { AsyncWhiteLabelBg } from "./AsyncWhiteLabelBg";
import { ExternalLink } from "components/common/routing/ExternalLink";

const UnauthLayout = (props: {children: ReactNode}) => {
  const { currentUser, currentUserStatus } = useCurrentUser();
  const isAuth = currentUserStatus === "authorized";
  const oauthRouteMatch = useRouteMatch<any>(oauthPath);
  const needAppsumoLogo = oauthRouteMatch?.params?.provider === "appsumo";
  const isWhiteLabel = useSelector(getIsWhiteLabel);
  const companyName = useSelector(getWhiteLabelName) || "GetSales";

  if (currentUser?.email_status === "unverified") {
    if (window.location.pathname !== userConfirmationPath) {
      browserHistory.push(userConfirmationPath);
    }
  }

  if (isAuth && currentUser?.email_status === "verified") {
    browserHistory.push("/");
    return <Preloader />;
  }

  return (
    <div className={styles.wrapper}>
      <Flex
        vertical
        gap={40}
        justify="center"
        className={styles.content}
      >
        <Flex
          gap="middle"
          align="flex-end"
        >
          <FullLogo style={{ maxHeight: 52, width: "fit-content" }} />
          {needAppsumoLogo && (
            <>
              <span style={{ position: "relative", bottom: 12 }}>with</span>
              <AppsumoLogo style={{ width: 118, height: "fit-content", alignSelf: "center" }} />
            </>
          )}
        </Flex>
        {props.children}
      </Flex>
      <div className={styles.staticRight}>
        <div style={{ width: 752, margin: "auto" }}>
          <div
            className="gs-fs-28 gs-fw-500"
            style={{ color: "white", marginBottom: 10 }}
          >
            Unified Inbox, Unmatched Security
          </div>
          <div
            className="gs-fs-20 gs-mb-xl"
            style={{ color: "white" }}
          >
            Join Outbound Teams Winning Smart! 🧠 🔥
          </div>

          {isWhiteLabel && (
            <AsyncWhiteLabelBg />
          )}
          {!isWhiteLabel && (
            <>
              <Flex
                gap={19}
                justify="space-between"
                className="gs-mb"
              >
                <div style={{ width: 366, minHeight: 341, background: "white", padding: 20, borderRadius: 20 }}>
                  <div style={{ color: "#1C212D", fontSize: 20, lineHeight: "26px", fontWeight: 600, marginBottom: 8 }}>
                    "Top Choice for Agencies"
                  </div>
                  <div style={{ color: "#313745", marginBottom: 19, lineHeight: "20px" }}>
                    We've tried many software solutions, including Expandi, but GetSales is the clear winner. Its robust features and user-friendly interface make it invaluable for managing large volumes of connections efficiently. What sets GetSales apart is its commitment to continuous improvement and innovation, along with a supportive and reliable team. GetSales is the best choice for any agency aiming to excel in lead generation.
                  </div>
                  <Flex gap={12}>
                    <img
                      src={ava1Base64}
                      width={62}
                      height={62}
                    />
                    <div>
                      <div style={{ fontSize: 14, lineHeight: "22px", marginBottom: 2 }}>
                        <ExternalLink
                          to="https://www.linkedin.com/in/aleksandr-mishyn/"
                          style={{ color: "#1C212D", fontWeight: 500, textDecoration: "underline" }}
                        >
                          Oleksandr Mishyn
                        </ExternalLink>
                        {" 🇺🇦"}
                      </div>
                      <div style={{ color: "#4E545F", fontSize: 12, lineHeight: "18px" }}>
                        CEO & Founder
                      </div>
                      <ExternalLink
                        to="https://grinfi.io/"
                        style={{ color: "#4E545F", fontSize: 12, lineHeight: "18px", textDecoration: "underline" }}
                      >
                        Grinifi.io Lead Generation Agency
                      </ExternalLink>
                    </div>
                  </Flex>
                </div>


                <div style={{ width: 366, minHeight: 341, background: "white", padding: 20, borderRadius: 20 }}>
                  <div style={{ color: "#1C212D", fontSize: 20, lineHeight: "26px", fontWeight: 600, marginBottom: 8 }}>
                    "Our go-to choice for LinkedIn"
                  </div>
                  <div style={{ color: "#313745", marginBottom: 8, lineHeight: "20px" }}>
                    I absolutely love this software. Safety, stability, and API - everything a GTM engineer needs in 2025. Plus, the best market price!
                  </div>
                  <div style={{ color: "#313745", marginBottom: 51, lineHeight: "20px" }}>
                    After thorough testing, we migrated all our projects to GetSales. I’m happy to say we’ve even dedicated two lessons of our course to its setup.
                  </div>
                  <Flex gap={12}>
                    <img
                      src={ava2Base64}
                      width={62}
                      height={62}
                    />
                    <div>
                      <div style={{ fontSize: 14, lineHeight: "22px", marginBottom: 2 }}>
                        <ExternalLink
                          to="https://www.linkedin.com/in/penn-frank/"
                          style={{ color: "#1C212D", fontWeight: 500, textDecoration: "underline" }}
                        >
                          Penn Frank
                        </ExternalLink>
                        {" 🇬🇧"}
                      </div>
                      <div style={{ color: "#4E545F", fontSize: 12, whiteSpace: "pre", lineHeight: "18px" }}>
                        Co-Founder & Author of
                        {" "}
                        <ExternalLink
                          to="https://tally.so/r/nrGDG2"
                          style={{ textDecoration: "underline", color: "#1C212D" }}
                        >
                          #1 GTM Course
                        </ExternalLink>
                      </div>
                      <ExternalLink
                        to="https://www.stackoptimise.com/"
                        style={{ color: "#4E545F", fontSize: 12, lineHeight: "18px", textDecoration: "underline" }}
                      >
                        StackOptimise GTM Engineer Agency
                      </ExternalLink>
                    </div>
                  </Flex>
                </div>
              </Flex>

              <Flex
                align="center"
                className="gs-mb-xl"
              >
                <Flex
                  gap="small"
                  className="gs-mr"
                >
                  {new Array(5).fill(null).map((_, index) => (
                    <svg
                      key={index}
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M5.41848 23.1641C4.83882 23.462 4.18247 22.9413 4.29917 22.2761L5.54376 15.1819L0.260264 10.1469C-0.233507 9.67639 0.0220934 8.81605 0.683577 8.72208L8.03129 7.67827L11.3075 1.18846C11.6026 0.603848 12.402 0.603848 12.6972 1.18846L15.9733 7.67827L23.3211 8.72208C23.9825 8.81605 24.2381 9.67639 23.7444 10.1469L18.4609 15.1819L19.7055 22.2761C19.8222 22.9413 19.1658 23.462 18.5862 23.1641L12.0023 19.7802L5.41848 23.1641Z"
                        fill="#FF7A68"
                      />
                    </svg>
                  ))}
                </Flex>
                <div
                  style={{ color: "#EFF2F5", marginRight: 6 }}
                  className="gs-fw-700 gs-fs-20"
                >
                  4.9/5
                </div>
                <div
                  style={{ color: "#EFF2F5" }}
                  className="gs-fs-16"
                >
                  based on 300 reviews
                </div>
              </Flex>
              <BgBadges />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UnauthLayout;
