import type { FC, ReactNode } from "react";
import type { TabsProps } from "@gs/uiKit/dataDisplay/Tabs";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ContentHeaderTabs } from "feature/mainLayout/component/ContentHeader/ContentHeaderTabs";
import { InnerLink } from "components/common/routing/InnerLink";
import { LogicError } from "@gs/core/errors/LogicError";

type ContentHeaderTabsNavigation = {
  keepSearch?: boolean
  tabs: Array<{
    exact?: boolean
    label: ReactNode
    path: string
  }>
}

export const ContentHeaderTabsNavigation: FC<ContentHeaderTabsNavigation> = (props) => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const tabItems = useMemo<TabsProps["items"]>(
    () => props.tabs.map((tab, index) => ({
      label: (
        <div>
          {/* InnerLink positioned absolut so behavior like ctrl+click works on whole surface */}
          <InnerLink
            tabIndex={-1}
            to={tab.path + (props.keepSearch ? search : "")}
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
            onClick={(e) => e.stopPropagation()}
          >
          </InnerLink>
          {tab.label}
        </div>
      ),
      key: String(index),
    })),
    [props.tabs, props.keepSearch && search],
  );

  const tabActiveKey = useMemo(
    () => String(props.tabs.findIndex((tab) => tab.exact
      ? pathname === tab.path
      : pathname.startsWith(tab.path),
    )),
    [props.tabs, pathname],
  );

  const onTabChange = useCallback(
    (index: string) => {
      const tab = props.tabs[Number(index)];
      if (!tab) return console.warn(new LogicError("ContentHeaderTabsNavigation corrupted data"));
      history.push(tab.path);
    },
    [props.tabs],
  );


  return (
    <ContentHeaderTabs
      activeKey={tabActiveKey}
      items={tabItems}
      onChange={onTabChange}
    />
  );
};
