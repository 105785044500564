import type { AxiosInstance, AxiosRequestConfig } from "axios";
import type { ApiListResponse, OrderType } from "../../commonTypes";
import type { SubscriptionFilter } from "./SubscriptionFilter";
import { subscriptionFilterToServerFormat } from "./SubscriptionFilter";
import type { Subscription, SubscriptionId } from "./Subscription";
import type { PlanId } from "../Plan/Plan";
import type { SubscriptionMetrics } from "./SubscriptionMetrics";

export const createSubscriptionApi = (axios: AxiosInstance) => {
  const getSubscriptions = (
    params: {
      filter?: SubscriptionFilter
      flexOnly?: boolean
      teamLevel?: boolean
      limit: number
      offset: number
      orderField?: string
      orderType?: OrderType
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.get<ApiListResponse<Subscription>>(
      "/api/subscriptions",
      {
        ...config,
        params: {
          order_field: params.orderField,
          order_type: params.orderType,
          limit: params.limit,
          offset: params.offset,
          filter: subscriptionFilterToServerFormat(params.filter),
          flex_only: params.flexOnly ? 1 : undefined,
          team_level: params.teamLevel ? 1 : undefined,
        },
      },
    );
  };

  const getSubscription = (
    params: {
      id: SubscriptionId
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.get<Subscription>(
      `/api/subscriptions/${params.id}`,
      config,
    );
  };

  const cancelSubscription = (
    params: {
      id: SubscriptionId
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.put<Subscription>(
      `/api/subscriptions/${params.id}/cancel`,
      {},
      config,
    );
  };

  const updateSubscription = (
    params: {
      id: SubscriptionId
      settings: {
        quantity?: number
        planId?: PlanId
      }
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.put<Subscription>(
      `/api/subscriptions/${params.id}/update-plan`,
      {
        quantity: params.settings.quantity,
        plan_id: params.settings.planId,
      },
      config,
    );
  };

  const getSubscriptionMetrics = (
    params: {
      ids: SubscriptionId[]
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.post<Record<SubscriptionId, SubscriptionMetrics>>(
      "/api/subscriptions/metrics",
      {
        ids: params.ids,
      },
      config,
    );
  };


  return {
    getSubscription,
    getSubscriptions,
    cancelSubscription,
    updateSubscription,
    getSubscriptionMetrics,
  };
};
