import moment from "moment";
import _ from "lodash";
import { encode, decode } from "js-base64";
import { sessionStore } from "feature/session/sessionStore";
import { dataBus } from "services/dataBus";
import { toastNotifications } from "feature/toastNotifications/toastNotifications";

export const htmlDecode = (input) => {
  const doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
};
export const escapeHTML = (html) => {
  const ta = document.createElement("textarea");
  ta.textContent = html;
  return ta.innerHTML;
};

const findGetParameter = (parameterName) => {
  let result = null;
  let tmp = [];

  window.location.search
    .substr(1)
    .split("&")
    .forEach(function (item) {
      tmp = item.split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });

  return result;
};

const stringToBoolean = (str) => {
  if (!str) {
    return false;
  }

  switch (str.toLowerCase().trim()) {
    case "true":
    case "yes":
    case "1": {
      return true;
    }

    case "undefined":
    case "false":
    case "no":
    case "0":
    case null: {
      return false;
    }

    default: return Boolean(str);
  }
};

const searchToObject = () => {
  const obj = {};
  let tmp = [];

  window.location.search
    .substring(1)
    .split("&")
    .forEach((item) => {
      if ( item === "" ) {
        return;
      }

      tmp = item.split("=");

      obj[decodeURIComponent(tmp[0])] = decodeURIComponent(tmp[1]);
    });

  return obj;
};

const serializeGetParameters = (obj) => {
  const str = [];

  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  }

  return str.join("&");
};

const durationFormatter = (seconds) => {
  seconds = parseInt(seconds);

  if (seconds === 1) return "1 second";
  if (seconds < 60) return seconds + " seconds";

  let res = moment.duration(+seconds, "seconds").humanize();
  res = res.replace("a ", "1 ");
  res = res.replace("an ", "1 ");

  return res;
};
const customDurationFormatter = (seconds, orderLength = 3) => {
  if (seconds == 0) {
    return "0s";
  }

  const secondUnits = 1;
  const minuteUnits = 60 * secondUnits;
  const hourUnits = 60 * minuteUnits;
  const dayUnits = 24 * hourUnits;

  const order = [
    {
      key: "d",
      units: dayUnits,
    }, {
      key: "h",
      units: hourUnits,
    }, {
      key: "m",
      units: minuteUnits,
    }, {
      key: "s",
      units: secondUnits,
    },
  ].slice(-orderLength);

  let res = "";

  for (const next of order) {
    const amount = Math.floor(seconds / next.units);
    if (!amount) continue;
    if (res) res += " ";

    res += `${amount}${next.key}`;
    seconds -= amount * next.units;
  }

  return res;
};

const addKeyToMap = (objMap, keyName = "key") =>
  Object.keys(objMap)
    .reduce((acc, key) => {
      acc[key] = {
        ...objMap[key],
        [keyName]: key,
      };
      return acc;
    }, {});

const orderObjectByKeys = (unordered) => {
  const ordered = {};

  Object.keys(unordered)
    .sort()
    .forEach((key) => ordered[key] = unordered[key]);

  return ordered;
};

const createCachedFunction = (originalFunction, theOptions = {}) => {
  let cachedResults = {};
  let timerId;

  const defaultOptions = {
    clearOnFail: true,
    clearTimer: false,
    recursively: false,
  };

  const options = {
    ...defaultOptions,
    ...theOptions,
  };
  const clearCache = () => {
    cachedResults = {};
    clearTimeout(timerId);
  };

  dataBus.subscribe("currentUser:logout", clearCache);

  return {
    clearCache,
    cachedFunction: (...params) => {
      const currentUserId = sessionStore.getState().userId;
      const cachedKey = JSON.stringify(params) + currentUserId; // TODO:  + currentUserId от этой гадости нужно избавиться
      if (cachedKey in cachedResults) return cachedResults[cachedKey];

      cachedResults[cachedKey] = originalFunction.apply(null, params);

      if (options.recursively && typeof cachedResults[cachedKey] === "function") {
        cachedResults[cachedKey] = createCachedFunction(cachedResults[cachedKey], options).cachedFunction;
      }

      if (options.clearOnFail) {
        Promise.resolve(cachedResults[cachedKey]).catch(() => delete cachedResults[cachedKey]);
      }
      if (options.clearTimer) {
        timerId = setTimeout( () => delete cachedResults[cachedKey], options.clearTimer);
      }

      return cachedResults[cachedKey];
    },
  };
};

// 232 -> 232
// 23422 -> 23.4k
// 13 223 422 -> 13.2m
const kFormatter = (num) => {
  if (num > 99999) {
    return (num / 1000000).toFixed(1) + "m";
  } else if (num > 999) {
    return (num / 1000).toFixed(1) + "k";
  }
  return num;
};

export const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const promiseDebounce = (fn, wait) => {
  let timerId;

  return (...props) => {
    clearTimeout(timerId);
    return new Promise((resolve) => {
      timerId = setTimeout(() => {
        resolve(fn(...props));
      }, wait);
    });
  };
};

const sendNotification = (title, body) => {
  const options = {
    body,
    icon: "/dist/icon_37.png",
  };

  if (!("Notification" in window)) {

  } else if (Notification.permission === "granted") {
    var notification = new Notification(title, options);
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission(function (permission) {
      if (permission === "granted") {
        var notification = new Notification(title, options);
      }
    });
  } else {
    // Notification.permission === 'denied'
  }
};

const getError = (response, defaultMessage = "Something went wrong. Please contact the support.") => {
  if (typeof response === "string" && !/^<!DOCTYPE html>/.test(response) && !/^<html>/.test(response)) return response;
  if (!response || response.responseCode >= 500) return defaultMessage;
  if (![422].includes(response.responseCode)) return response?.message || response?.data?.message || defaultMessage;

  try {
    let respObj;
    if (response.error) {
      respObj = response.error.data;
    } else {
      respObj = response;
    }
    const dataKeys = Object.keys(_.omit(respObj, "responseCode"));

    if (!dataKeys.length) {
      return response.error.message || defaultMessage;
    }

    let errorMessage = respObj[dataKeys[0]];
    if (Array.isArray(errorMessage)) {
      errorMessage = errorMessage[0];
    }
    if (Array.isArray(errorMessage)) {
      errorMessage = errorMessage[0];
    }

    return errorMessage || defaultMessage;
  } catch (e) {
    return defaultMessage;
  }
};

const showError = (response, defaultMessage, options) => {
  toastNotifications.open({
    type: "error",
    content: getError(response, defaultMessage),
    ...options,
  });
};

const showSuccess = (message, options) => {
  toastNotifications.open({
    type: "success",
    content: message,
    ...options,
  });
};

const handleDate = (dateString) => {
  let date = moment(dateString, "YYYY-MM-DD HH:mm:ss").toDate();
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return moment(date);
};

const formatDate = (dateString) => {
  const date = handleDate(dateString);
  const year = "" + new Date().getFullYear();
  let formattedDate = date.format("D MMM YYYY");
  formattedDate = formattedDate.replace(RegExp(" " + year + "$"), "");

  return formattedDate;
};

const addTimezoneOffset = (momentDate) => momentDate.add((new Date).getTimezoneOffset(), "minutes");

const removeClass = (elem, cls) => {
  var classes = elem.className.split(" ");

  for (var i = 0; i < classes.length; i++) {
    if (classes[i] == cls) {
      classes.splice(i, 1);
      i--;
    }
  }
  elem.className = classes.join(" ");
};

const getLocationString = (location, options = {}) => {
  if (!location) {
    return "";
  }

  if (options.short) {
    return location.city + ", " + location.country;
  }

  if (location.address) {
    return location.address;
  }
  // full
  if (location.address_string) {
    return location.address_string;
  }

  let address = (location.country || "") + ", " + (location.state || "") + ", " + (location.city || "") + ", "
    + (location.street || "") + " " + (location.street_number || "") + ", " + (location.postal_code || "");
  address = address.replace(/^(,\s*)+/, "").replace(/(,\s*)+/g, ", ").replace(/,\s*$/, "").trim();

  if (!address && location.address) {
    return location.address;
  }

  return address;
};

//for text constant
const firstToUpperCase = (str) => {
  str = str.replace("_", " ");
  return str.substr(0, 1).toUpperCase() + str.substr(1).toLowerCase();
};

export const handleHtmlNewLines = (html) =>
  html
    .replace(/(<\/p>)/ig, "\n")
    .replace(/<div([^>]*)><br>/ig, "\n")
    .replace(/<div([^>]*)>/ig, "\n")
    .replace(/<br>/ig, "\n");
const cutHtmlTags = (html) => {
  return handleHtmlNewLines(html)
    .replace(/<head(.|\n)*<\/head>/, "")
    .replace(/(<([^>]+)>)/ig, "");
  // const div = document.createElement("div");
  // div.innerHTML = html;
  //
  // return div.textContent || div.innerText || "";
};

const getTextContentFromHtml = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html;

  return div.textContent || div.innerText || "";
};

const urlToFile = (url, filename = "a", mimeType) => {
  mimeType = mimeType || (url.match(/^data:([^;]+);/) || "")[1];

  return fetch(url)
    .then( (res) => res.arrayBuffer() )
    .then( (buf) => new File([buf], filename, { type: mimeType }) );
};

const copyToClipboard = (text) => {
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text);
  }

  const el = document.createElement("textarea");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  el.setAttribute("readonly", "");
  el.value = text;

  document.body.appendChild(el);
  el.select();
  const success = document.execCommand("copy");
  document.body.removeChild(el);
  return success;
};

const encodeToBase64 = (value) => {
  if (typeof value === "string") return encode(value);
  return encode(JSON.stringify(value));
};

const decodeFromBase64 = (base64String) => {
  if (!base64String) return;
  return JSON.parse(decode(base64String));
};

const downloadFileFromResponse = ({ file, filename }) => {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();

  URL.revokeObjectURL(url);
  link.remove();
};

const highlightVariables = ({ text, variables = "First Name" }) => text
  .replace(/(\[\[.+?]])/g,
    "<span style=\"background-color: #F5C26B\">$1</span>")
  .replace(RegExp(`(${variables})`, "g"),
    "<span style=\"background-color: #00a4bd33\">$1</span>");

export {
  findGetParameter,
  stringToBoolean,
  searchToObject,
  serializeGetParameters,
  durationFormatter,
  customDurationFormatter,
  addKeyToMap,
  orderObjectByKeys,
  createCachedFunction,
  kFormatter,
  promiseDebounce,
  sendNotification,
  getError,
  showError,
  showSuccess,
  handleDate,
  formatDate,
  addTimezoneOffset,
  removeClass,
  getLocationString,
  firstToUpperCase,
  cutHtmlTags,
  getTextContentFromHtml,
  urlToFile,
  copyToClipboard,
  encodeToBase64,
  decodeFromBase64,
  highlightVariables,
  downloadFileFromResponse,
};
