import { createBrowserHistory } from "history";

const history = createBrowserHistory();

// const originPush = history.push;
// const originReplace = history.replace;
//
// history.push = function (...args) {
//   console.trace("history.push", ...args);
//   return originPush.call(this, ...args);
// };
//
// history.replace = function (...args) {
//   console.trace("history.replace", ...args);
//   return originReplace.call(this, ...args);
// };

export default history;

