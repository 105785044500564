import basePath from "../path";
import type { FlowId } from "@gs/core/domain/Flow/Flow";

export const FLOW_ID_PARAM_NAME = "id";

export const flowDetailsPath = `${basePath}/:${FLOW_ID_PARAM_NAME}`;

export const getLinkToFlowDetails = (params: {id: FlowId}) => {
  return flowDetailsPath.replace(`:${FLOW_ID_PARAM_NAME}`, params.id);
};
