import "./styles/global.module.scss";
import React from "react";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import store from "store/store";
import { App } from "./components/App";
import { GlobalMaintenance } from "./components/GlobalMaintenance";
import { loadWhiteLabelLogos } from "./store/whiteLabel/actionCreators";
import { initBugsnag } from "./services/bugsnag";
import { featureFlag } from "./services/featureFlag";
import { sessionService } from "./feature/session/SessionService";
import Preloader from "components/common/elements/Preloader";
// import reportWebVitals from "./reportWebVitals";

(async () => {
  const container = document.getElementById("root")!;
  const root = createRoot(container);
  if (!new URLSearchParams(window.location.search).has("backdoor")) {
    //return root.render(<GlobalMaintenance />);
  }
  root.render(<Preloader />);
  try {
    if (featureFlag.bugsnag) {
      initBugsnag();
    }
    const domain = new URLSearchParams(window.location.search).get("_white_label_test_domain_") || window.location.hostname;
    await store.dispatch(loadWhiteLabelLogos(domain));
    await sessionService.loadCurrentUser();
  } finally {
    root.render(
      <Provider store={store}>
        <App />
      </Provider>,
    );
  }
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
