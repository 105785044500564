import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import { ModuleLoaderError } from "components/common/ModuleLoaderError";

export { default as path } from "./path";

export const AsyncTagsPage = Loadable({
  loader: () => {
    return import(/* webpackChunkName:'TagsPage' */ "./TagsPage")
      .catch( (e) => {
        console.log("Tags Page loading err", e);
        return ModuleLoaderError;
      });
  },
  // @ts-ignore
  loading: Loading,
});

export default AsyncTagsPage;

