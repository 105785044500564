import type { AxiosInstance, AxiosRequestConfig } from "axios";
import type { ApiListResponse, Mixed, OrderType } from "../../commonTypes";
import type { Flow, FlowId } from "./Flow";
import type { FlowMetrics } from "./FlowMetrics";
import type { FlowWorkspaceId } from "../FlowWorkspace/FlowWorkspace";
import type { FlowVersion, FlowVersionId } from "../FlowVersion/FlowVersion";
import type { UserId } from "../User/User";
import type { SenderProfileId } from "../SenderProfile/SenderProfile";

type FlowPayload = Pick<Flow, "name" | "priority" | "schedule" | "flow_workspace_uuid" | "is_public" | "use_sender_schedule">

export const createFlowApi = (axios: AxiosInstance) => {
  const getFlows = (
    params: {
      filter?: {
        query?: string
        id?: Mixed<FlowId>
        flowWorkspaceId?: Mixed<FlowWorkspaceId>
        flowVersionId?: Mixed<FlowVersionId>
        status?: Mixed<Flow["status"]>
        isPublic?: Mixed<Flow["is_public"]>
        userId?: Mixed<UserId>
        withDeleted?: Mixed<boolean>
      }
      limit: number
      offset: number
      orderField?: string
      orderType?: OrderType
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.post<ApiListResponse<Flow>>(
      "/api/flows/list",
      {
        order_field: params.orderField,
        order_type: params.orderType,
        limit: params.limit,
        offset: params.offset,
        filter: {
          q: params.filter?.query,
          uuid: params.filter?.id,
          flow_workspace_uuid: params.filter?.flowWorkspaceId,
          flow_version_uuid: params.filter?.flowVersionId,
          status: params.filter?.status,
          is_public: params.filter?.isPublic,
          user_id: params.filter?.userId,
          deleted: params.filter?.withDeleted,
        },
      },
      config,
    );
  };

  const getFlow = (params: {id: FlowId}) => {
    return axios.get<Flow>(`/api/flows/${params.id}`);
  };

  const createFlow = (params: {fields: FlowPayload }, config?: AxiosRequestConfig) => {
    return axios.post<Flow>("/api/flows", params.fields, config);
  };

  const cloneFlow = (params: {fields: FlowPayload & Pick<FlowVersion, "nodes" | "contact_sources" | "first_common_node_id"> }, config?: AxiosRequestConfig) => {
    return axios.post<Flow>(
      "/api/flows/clone",
      params.fields,
      config,
    );
  };

  const updateFlow = (params: { id: FlowId; newFields: FlowPayload }, config?: AxiosRequestConfig) => {
    return axios.put<Flow>(`/api/flows/${params.id}`, params.newFields, config);
  };

  const deleteFlow = (params: { id: FlowId }, config?: AxiosRequestConfig) => {
    return axios.delete<void>(`/api/flows/${params.id}`, config);
  };

  const getPublicFlow = (params: {id: FlowId}, config?: AxiosRequestConfig) => {
    return axios.get<Flow>(`/api/flows/${params.id}/share`, config);
  };

  const createFlowPublicLink = (params: {id: FlowId}, config?: AxiosRequestConfig) => {
    return axios.put<Flow>(`/api/flows/${params.id}/share-link`, null, config);
  };

  const getFlowMetrics = (params: {flowIds: FlowId[]}, config?: AxiosRequestConfig) => {
    return axios.post<Record<FlowId, FlowMetrics>>(
      "/api/flows/metrics",
      { uuids: params.flowIds },
      config,
    );
  };

  const getAllSenderProfilesForFlows = (params: {flowIds: FlowId[]}, config?: AxiosRequestConfig) => {
    return axios.post<Record<FlowId, SenderProfileId[]>>(
      "/api/flows/all-sender-profiles",
      { uuids: params.flowIds },
      config,
    );
  };

  const startFlow = (params: {flowId: FlowId}, config?: AxiosRequestConfig) => {
    return axios.put<Flow>(
      `/api/flows/${params.flowId}/start`,
      null,
      config,
    );
  };

  const stopFlow = (params: {flowId: FlowId}, config?: AxiosRequestConfig) => {
    return axios.put<Flow>(
      `/api/flows/${params.flowId}/stop`,
      null,
      config,
    );
  };

  const archiveFlow = (params: {flowId: FlowId}, config?: AxiosRequestConfig) => {
    return axios.put<Flow>(
      `/api/flows/${params.flowId}/archive`,
      null,
      config,
    );
  };

  const unarchiveFlow = (params: {flowId: FlowId}, config?: AxiosRequestConfig) => {
    return axios.put<Flow>(
      `/api/flows/${params.flowId}/unarchive`,
      null,
      config,
    );
  };

  const makePublic = (params: {flowId: FlowId}, config?: AxiosRequestConfig) => {
    return axios.post<Flow>(
      `/api/flows/${params.flowId}/make-public`,
      null,
      config,
    );
  };

  return {
    getFlows,
    getFlow,
    createFlow,
    cloneFlow,
    updateFlow,
    deleteFlow,
    getPublicFlow,
    createFlowPublicLink,
    archiveFlow,
    unarchiveFlow,
    getFlowMetrics,
    getAllSenderProfilesForFlows,
    startFlow,
    stopFlow,
    makePublic,
  };
};
