import type { AxiosInstance, AxiosRequestConfig } from "axios";
import type { GologinProfileId, LinkedinBrowser, LinkedinBrowserId } from "../LinkedinBrowser/LinkedinBrowser";
import type { LinkedinBrowserProxy } from "../LinkedinBrowser/LinkedinBrowser";
import type { SenderProfileId } from "../SenderProfile/SenderProfile";
import type { ApiListResponse, Country, Mixed } from "../../commonTypes";
import type { LinkedinAccountId } from "../LinkedinAccount/LinkedinAccount";
import type { UserId } from "../User/User";

export type ProxyInfo = {
  city: string
  country_code: Country
  country: string
  ip: string
}

export type LinkedinBrowserFilter = {
  id?: Mixed<LinkedinBrowserId>
  senderProfileId?: Mixed<SenderProfileId>
  linkedinAccountId?: Mixed<LinkedinAccountId>
  gologinProfileId?: Mixed<GologinProfileId>
  runningIp?: Mixed<string>
  status?: Mixed<LinkedinBrowser["status"]>
  userId?: Mixed<UserId>
  withDeleted?: boolean
}

export const createLinkedinBrowserApi = (axios: AxiosInstance) => {
  const getLinkedinBrowser = (params: {id: LinkedinBrowserId}, config?: AxiosRequestConfig) => {
    return axios.get<LinkedinBrowser>(`/api/linkedin-browsers/${params.id}`, config);
  };

  const getLinkedinBrowsers = (
    params: {
      filter?: LinkedinBrowserFilter
      limit: number
      offset: number
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.post<ApiListResponse<LinkedinBrowser>>(
      "/api/linkedin-browsers/list",
      {
        limit: params.limit,
        offset: params.offset,
        filter: {
          id: params.filter?.id,
          sender_profile_uuid: params.filter?.senderProfileId,
          linkedin_account_uuid: params.filter?.linkedinAccountId,
          external_id: params.filter?.gologinProfileId,
          running_ip: params.filter?.runningIp,
          status: params.filter?.status,
          user_id: params.filter?.userId,
          deleted: params.filter?.withDeleted,
        },
      },
      config,
    );
  };

  const createLinkedinBrowser = (
    params: {
      proxy?: LinkedinBrowserProxy
      senderProfileId: SenderProfileId
      gologinProfileId?: GologinProfileId
      linkedinLogin?: string
      linkedinPassword?: string
      proxyType?: string
      proxyCountryCode?: string
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.post<LinkedinBrowser>(
      "/api/linkedin-browsers",
      {
        custom_proxy_config: params.proxy,
        sender_profile_uuid: params.senderProfileId,
        external_id: params.gologinProfileId,
        linkedin_login: params.linkedinLogin,
        linkedin_password: params.linkedinPassword,
        linkedin_proxy_type: params.proxyType,
        proxy_country_code: params.proxyCountryCode,
      },
      config,
    );
  };

  const deleteLinkedinBrowser = (
    params: {
      id: LinkedinBrowserId
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.delete<LinkedinBrowser>(
      `/api/linkedin-browsers/${params.id}`,
      config,
    );
  };

  const updateLinkedinBrowser = (
    params: {
      newFields: {
        status?: LinkedinBrowser["status"]
        errorReason?: string
        proxy?: LinkedinBrowserProxy
        linkedinLogin?: string
        linkedinPassword?: string
      }
      id: LinkedinBrowserId
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.put<LinkedinBrowser>(
      `/api/linkedin-browsers/${params.id}`,
      {
        status: params.newFields.status,
        error_reason: params.newFields.errorReason,
        custom_proxy_config: params.newFields.proxy,
        linkedin_login: params.newFields.linkedinLogin,
        linkedin_password: params.newFields.linkedinPassword,
      },
      config,
    );
  };

  const shareLinkedinBrowser = (
    params: {
      linkedinBrowserId: LinkedinBrowserId
      emails: string[]
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.post<LinkedinBrowser>(
      `/api/linkedin-browsers/${params.linkedinBrowserId}/share`,
      {
        recipients: params.emails,
      },
      config,
    );
  };

  const runLinkedinBrowser = (params: {linkedinBrowserId: LinkedinBrowserId}, config?: AxiosRequestConfig) => {
    return axios.post(
      `/api/linkedin-browsers/${params.linkedinBrowserId}/run`,
      {},
      config,
    );
  };

  const stopLinkedinBrowser = (params: {linkedinBrowserId: LinkedinBrowserId}, config?: AxiosRequestConfig) => {
    return axios.post(
      `/api/linkedin-browsers/${params.linkedinBrowserId}/stop`,
      {},
      config,
    );
  };

  const checkProxy = (params: {proxy: LinkedinBrowserProxy}, config?: AxiosRequestConfig) => {
    return axios.post<ProxyInfo>("/api/linkedin-browsers/check-proxy", params, config);
  };

  const updateProxy = (
    params: {
      id: LinkedinBrowserId
      proxy?: LinkedinBrowserProxy
      proxyType?: string
      proxyCountryCode?: string
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.post<LinkedinBrowser>(
      `/api/linkedin-browsers/${params.id}/set-proxy`,
      {
        custom_proxy_config: params.proxy,
        proxy_country_code: params.proxyCountryCode,
        linkedin_proxy_type: params.proxyType,
      },
      config,
    );
  };

  const createGologinAccount = (
    params: {
      login: string
      password: string
    },
    config?: AxiosRequestConfig,
  ) => {

    return axios.post<unknown>(
      "/api/gologin/create-user",
      {
        email: params.login,
        password: params.password,
      },
      config,
    );
  };

  return {
    getLinkedinBrowser,
    getLinkedinBrowsers,
    createLinkedinBrowser,
    updateLinkedinBrowser,
    deleteLinkedinBrowser,
    shareLinkedinBrowser,
    stopLinkedinBrowser,
    runLinkedinBrowser,
    checkProxy,
    updateProxy,

    createGologinAccount, // separate domain
  };
};
