import { useState, useCallback, useMemo, useEffect } from "react";
import { showError } from "services/utils";
import { createFlow } from "store/entities/flows/actions";
import { updateFlow } from "../List/reducer/actions";
import { getWorkspacesOptions } from "store/entities/flowWorkspaces/actions";
import { getTimezonesOptions } from "services/selectors";
import { DayRow } from "./DayRow";
import TextRow from "components/common/inputs/FormRows/TextRow";
import SelectRow from "components/common/inputs/FormRows/SelectRow";
import CheckBox from "components/common/inputs/CheckBox";
import Icon from "../../../../common/elements/Icon";
import { workspacesSelector } from "store/entities/flowWorkspaces/selectors";
import type { TFlowData, Ttimeblocks } from "store/entities/flows/types";
import { useDispatch, useSelector } from "react-redux";
import AsyncActionButton from "components/common/buttons/AsyncActionButton";
import type { IModalContentProps } from "./interfaces";
import { filters as getFilters } from "./../List/reducer/selectors";
import styles from "./NewOrEditModal.module.scss";
// import { cloneFlow } from "store/entities/flows/actions";
import { cloneAutomation as cloneFlow } from "../List/reducer/actions";
import { dataBus } from "services/dataBus";

const defaultWorkspace = {
  value: null,
  label: "Default Workspace",
};

const DEFAULT_TIMEZONE: any = "UTC";

const _9am = 540;

const _6pm = 1080;

const defaultTimeblock = [
  { dow: 1, max: _6pm, min: _9am },
  { dow: 2, max: _6pm, min: _9am },
  { dow: 3, max: _6pm, min: _9am },
  { dow: 4, max: _6pm, min: _9am },
  { dow: 5, max: _6pm, min: _9am },
];

export const ModalContent = ({ onRequestClose, newVersionId, flow, isClone = false, isTemplate, onCloneTemplate }: IModalContentProps) => {
  const filter = useSelector(getFilters);
  const workspaceUui = filter.flow_workspace_uuid;
  const currentFilter = useSelector((state: any) => state.entities.flowWorkspaces[workspaceUui]);
  const dispatch = useDispatch();
  const workspacesData = useSelector(workspacesSelector);

  const [form, setForm] = useState<TFlowData | { [key: string]: any }>(flow || {
    flow_workspace_uuid: workspaceUui ? currentFilter?.uuid : null,
    is_public: 0,
    schedule: {
      timezone: DEFAULT_TIMEZONE,
      timeblocks: defaultTimeblock,
    },
  });

  const timeBlocksData = useMemo(() => {
    if (!form.schedule) return new Array(7).fill(null);

    return new Array(7).fill(null).map((_, index) => {
      //@ts-ignore
      const block = form.schedule.timeblocks.find(({ dow }) => dow === index);
      return block || null;
    });
  }, [form?.schedule]);
  const handleChange = ({ key, value }: { key: string; value: string | number }) => {
    setForm((prev: any) => ({
      ...prev,
      [key]: value,
    }));
  };

  // @ts-ignore
  const onSubmit = async (e) => {
    e.preventDefault();
    if (isClone) {
      await dispatch(cloneFlow({ flowVersionUuid: flow?.flow_version_uuid, flowData: form, isTemplate }))
        //@ts-ignore
        .then(() => {
          onRequestClose();
          if (onCloneTemplate) {
            onCloneTemplate();
          }

          dataBus.emit("flow:created", { flowIds: [] });
        });
    } else {
      const action = flow ? updateFlow : createFlow;
      await dispatch(action({
        // @ts-ignore
        uuid: flow?.uuid,
        params: {
          ...form,
          flow_version_uuid: newVersionId || flow?.flow_version_uuid,
        },
      }))
        //@ts-ignore
        .then(() => {
          onRequestClose();
        })
        .catch((err: string) => {
          console.error(err);
          showError(err, "Failed");
        });
    }

  };

  const addTimeBlock = (block: Ttimeblocks) => {
    const timeBlocks = form.schedule ? form.schedule.timeblocks : [];
    updateTimeBlockState([
      ...timeBlocks,
      block,
    ]);
  };

  const removeTimeBlock = (day: number) => {
    const timeblocks = (form.schedule ? form.schedule.timeblocks : [])
      .filter((block: Ttimeblocks) => block.dow !== day);

    updateTimeBlockState(timeblocks);
  };

  const updateTimeBlock = (day: number, value: { min: number; max: number }) => {
    const timeblocks = (form.schedule ? form.schedule.timeblocks : [])
      .map((block: Ttimeblocks) => {
        if (day === block.dow) {
          return {
            dow: day,
            ...value,
          };
        }
        return block;
      });
    updateTimeBlockState(timeblocks);
  };

  const updateTimeBlockState = (timeblocks: Ttimeblocks[]) => {
    setForm((prev: any) => {
      return {
        ...prev,
        schedule: {
          ...prev.schedule,
          timeblocks,
        },
      };
    });
  };

  const handleTimezone = (newTimezone: string) => {
    setForm((prev) => ({
      ...prev,
      schedule: {
        ...prev.schedule,
        timezone: newTimezone,
      },
    }));
  };

  const getSelectInputValue = () => {
    const defaultValue = form.flow_workspace_uuid ? {
      value: form.flow_workspace_uuid,
      label: workspacesData?.[form.flow_workspace_uuid]?.name,
    } : defaultWorkspace;
    if (workspaceUui && currentFilter && !defaultValue.label) {
      return {
        value: currentFilter.value,
        label: currentFilter.name,
      };
    }
    return defaultValue;
  };
  useEffect(() => {
    if (isClone) {
      handleChange({ key: "name", value: form.name + "--copy" });
    }
  }, []);

  return (
    <form className={styles.Modal}>
      <div className={styles.ModalHeader}>
        <h2>
          {flow && !isClone ? "Edit" : flow && isClone ? "Clone" : "Create new"}
          {" "}
          Automation
        </h2>
        <div
          className={styles.ModalCloseBtn}
          onClick={(e) => onRequestClose()}
        >
          <Icon
            icon="Cross"
            color='#516F90'
            size={20}
          />
        </div>
      </div>
      <div className={styles.ModalInputWrap}>
        <TextRow
          value={form?.name || ""}
          required
          onChange={(e: any) => handleChange({ key: "name", value: e.target.value })}
          placeholder="Automation Name"
          label="Automation Name"
          autoFocus
        />
      </div>
      <div className={styles.ModalInputWrap}>
        <SelectRow
          required
          label='Workspace'
          placeholder='Workspace'
          loadOptions={getWorkspacesOptions}
          value={getSelectInputValue()}
          onChange={({ value }: { value: string; label: string }) => {
            handleChange({ key: "flow_workspace_uuid", value });
          }}
        />
      </div>
      {/* <label className={styles.ModalCheckbox}>
        <CheckBox
          checked={!!form.is_public}
          onChange={(e: any) => handleChange({key: 'is_public', value: Number(e.target.checked)})}
        />
        <p>Private Automation</p>
      </label> */}
      <div className={styles.ModalSchedules}>
        <div className={styles.ModalTimezoneRow}>
          <p>Timezone</p>
          <div className={styles.ModalTimezoneSelect}>
            <SelectRow
              placeholder="Time zone"
              loadOptions={getTimezonesOptions}
              value={{
                value: form.schedule.timezone || "DEFAULT_TIMEZONE",
                label: form.schedule.timezone || "DEFAULT_TIMEZONE",
              }}
              onChange={({ value }: { value: string; label: string }) => handleTimezone(value)}
            />
          </div>
        </div>
        <div className={styles.ModalSchedulesWrap}>
          {timeBlocksData.map((timeblock, index) =>
            (index !== 0 && <DayRow
              key={index}
              day={index}
              addTimeBlock={addTimeBlock}
              removeTimeBlock={removeTimeBlock}
              updateTimeBlock={updateTimeBlock}
              timeblock={timeblock}
            />),
          )}
          <DayRow
            day={0}
            addTimeBlock={addTimeBlock}
            removeTimeBlock={removeTimeBlock}
            updateTimeBlock={updateTimeBlock}
            timeblock={timeBlocksData[0]}
          />
        </div>
      </div>
      <div className={styles.ModalFooter}>
        <AsyncActionButton
          action={onSubmit}
        >
          {flow && !isClone ? "Update Automation" : flow && isClone ? "Clone Automation" : "Create Automation"}
        </AsyncActionButton>
      </div>
    </form>
  );
};
