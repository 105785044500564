import styles from "./TeamMenuContent.module.scss";
import { useAllTeams } from "domain/team/hook/useAllTeams";
import { Flex } from "@gs/uiKit/layout/Flex";
import { TeamAvatar } from "domain/team/component/TeamAvatar";
import { sessionService } from "feature/session/SessionService";
import Preloader from "components/common/elements/Preloader";
import { Typography } from "@gs/uiKit/general/Typography";
import { CheckOutlined } from "@ant-design/icons";
import cn from "classnames";
import { useMemo } from "react";
import { InnerLink } from "components/common/routing/InnerLink";
import teamsManagementPath from "components/routes/UserSettingsGroup/TeamsManagement/path";
import { Button } from "@gs/uiKit/general/Button";

export const TeamMenuContent = () => {
  const [teams, isLoading] = useAllTeams();
  const currentTeamId = sessionService.useStore((s) => s.teamId);

  const teamsWithSubscription = useMemo(() => {
    return teams.filter((theTeam) => theTeam.subscription_id);
  }, [teams]);

  return (
    <Flex
      vertical
      gap="small"
      // className="gs-p-xxs"
      style={{
        width: 220,
        maxHeight: 400,
        overflow: "auto",
      }}
    >
      {teamsWithSubscription.map((team) => (
        <Flex
          key={team.id}
          gap="small"
          align="center"
          className={cn({ [styles.teamButton as string]: team.id !== currentTeamId })}
          onClick={() => team.id !== currentTeamId && sessionService.changeTeam(team.id)}
        >
          <TeamAvatar teamId={team.id} />
          <Flex
            className="gs-grow"
            align="center"
          >
            <div className="gs-grow gs-w-0">
              <Typography.Text
                className="gs-fs-12 gs-fw-500"
                style={{ flex: "1 1 0" }}
                ellipsis={{ tooltip: team.name }}
              >
                { team.name }
              </Typography.Text>
              <div className="gs-fs-12 gs-c-secondary">
                #
                {team.id}
              </div>
            </div>
            { team.id === currentTeamId && (
              <CheckOutlined style={{ color: "var(--color-primary)" }} />
            )}
          </Flex>
        </Flex>
      ))}
      { isLoading && <Preloader />}
      <Flex justify="center">
        <InnerLink to={teamsManagementPath}>
          <Button type="link">
            Manage teams
          </Button>
        </InnerLink>
      </Flex>
    </Flex>
  );
};
